<template>
  <div>
    <form @submit.prevent="submit" class="mb-6">
      <h3 class="font-poppins">Company Information</h3>

      <div class="field mt-6">
        <label class="label">Company Name</label>
        <div class="control">
          <input v-model="form.fields.company_name" class="input" type="text" name="company[company_name]" />
        </div>
        <field-error :message="form.field_errors.company_name" />
      </div>

      <div class="field">
        <label class="label">Business Address</label>
        <div class="control">
          <input v-model="form.fields.address" class="input" type="text" name="company[Address]" />
        </div>
        <field-error :message="form.field_errors.address" />
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">State</label>
            <div class="control">
              <USStateSelector :selection.sync="form.fields.state" class="is-fullwidth"></USStateSelector>
            </div>
            <field-error :message="form.field_errors.state" />
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Zipcode</label>
            <div class="control">
              <input v-model="form.fields.zip_code" class="input" type="text" name="company[Zipcode]" />
            </div>
            <field-error :message="form.field_errors.zip_code" />
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Website</label>
        <div class="control">
          <input v-model="form.fields.website" class="input" type="text" name="company[website]" />
        </div>
        <field-error :message="form.field_errors.website" />
      </div>

      <h3 class="font-poppins">Account Manager</h3>

      <div class="field mt-6">
        <label class="label">Name</label>
        <div class="control">
          <input v-model="form.fields.user_name" class="input" type="text" name="company[user_name]" />
        </div>
        <field-error :message="form.field_errors.user_name" />
      </div>

      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input v-model="form.fields.email" class="input" type="email" name="company[email]" />
        </div>
        <field-error :message="form.field_errors.email" />
      </div>

      <div class="field" v-if="display_notification">
        <div class="notification is-success is-light">
          <button class="delete" @click.prevent="display_notification = false"></button>
          Your details have been successfully updated.
        </div>
      </div>

      <div class="field my-6">
        <div class="control">
          <button type="submit" class="button submit is-purple is-medium" :class="{ 'is-loading': is_loading }">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";
export default {
  name: "CompanyForm",

  mounted() {
    this.fetch();
  },

  data() {
    return {
      is_loading: false,
      display_notification: false,

      form: {
        fields: {
          company_name: "",
          user_name: "",
          email: "",
          address: "",
          state: "",
          zip_code: "",
          website: "",
        },

        field_errors: {},
        errors: [],
      },
    };
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.companies
        .update(this.auth_user.company_details.id, this.form.fields)
        .then(() => {
          this.show_success();
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    fetch() {
      this.is_loading = true;
      api.companies
        .fetch(this.auth_user.company_details.id)
        .then((response) => {
          this.form.fields = response.data;
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    show_success() {
      this.display_notification = true;

      setTimeout(() => {
        this.display_notification = false;
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
