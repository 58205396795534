import type from "./object_type";

export default function is_empty(object) {
  if (!object) {
    return true;
  }

  if (type(object) === "array") {
    return !object.length;
  }

  for (let property in object) {
    if (object.hasOwnProperty(property)) {
      return false;
    }
  }

  return true;
}

// export default {
//   is_empty
// };
