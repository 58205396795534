import Vue from "vue";

function Store(options) {
  let $controller = new Vue({
    data: () => {
      return {
        state: options.state
      };
    }
  });

  this.$controller = $controller;
  this.state = $controller.state;
}

function install(vue) {
  vue.mixin({
    beforeCreate: function () {
      const options = this.$options;
      // console.log("this.$options", options);
      // console.log("this.$options.parent", options.parent);
      // console.log("this.$options.store", this.$options.store);
      // console.log("--------------");

      if (options.store) {
        this.$store = typeof options.store === 'function' ? options.store() : options.store
      } else if (options.parent && options.parent.$store) {
        this.$store = options.parent.$store
      }
    }
  });
}

Store.install = install;
Store.prototype.set = function (property_name, value) {
  Vue.$set(this.state, property_name, value);
};

Vue.use(Store);

export default new Store({
  state: {
    auth: {
      is_loading: true,
      user: {}
    }
  }
});