<template>
  <public-layout>
    <div v-if="!show_success" class="password-confirm container">
      <h1 class="font-poppins mb-5">New Password</h1>

      <form @submit.prevent="submit">
        <div class="field mb-5">
          <div class="control">
            <input
              name="PasswordResetConfirm[Password]"
              class="input"
              type="password"
              v-model="form.fields.password"
              :disabled="is_loading"
              placeholder="Enter you new Password"
              required
            />
          </div>
          <field-error :message="form.field_errors.password" />
          <field-error :message="form.field_errors.token" />
        </div>
        <div class="field">
          <list-errors :errors="form.errors"></list-errors>
        </div>

        <div class="field mt-4">
          <div class="control">
            <button type="submit" class="button submit is-purple is-medium fill-width" v-is-loading="is_loading">
              RESET
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- POST RESET MESSAGE -->
    <div v-else class="password-reset container">
      <h1 class="font-poppins mb-5">Success</h1>
      <p>You have successfully set your password.</p>
      <p>
        <router-link class="purple-text mr-4" :to="{ name: 'login' }">Back to Login</router-link>
      </p>
    </div>
  </public-layout>
</template>

<script>
import api from "@/api";
import { set_errors } from "../utilities/form";

export default {
  name: "ResetPasswordConfirm",

  data() {
    return {
      show_password: false,
      show_success: false,
      is_loading: false,
      form: {
        fields: {
          password: "",
          token: this.$route.query.token,
        },
        field_errors: {},
        errors: [],
      },
    };
  },
  computed: {},
  methods: {
    async submit() {
      this.is_loading = true;

      api.accounts
        .confirm_password_reset(this.form.fields)
        .then(() => {
          this.show_success = true;
        })
        .catch((error) => {
          set_errors(this.form, error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.password-confirm.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.password-confirm.container h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.password-confirm.container form {
  width: 400px;
}
</style>
