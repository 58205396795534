<template>
  <div v-if="has_errors" class="list-errors notification is-danger is-light p-4">
    <ul :class="{ multiline: errors.length > 1 }">
      <li v-for="(error, index) in errors" :key="index">
        {{ error.message }}
      </li>
    </ul>
  </div>
</template>

<script>
const defaultErrors = () => {
  return [];
};

export default {
  name: "ListErrors",

  props: { errors: { type: Array, default: defaultErrors } },

  computed: {
    has_errors() {
      return !!(Array.isArray(this.errors) && this.errors.length > 0);
    }
  }
};
</script>

<style>
.list-errors {
  font-size: 14px;
}

.list-errors ul:not(.multiline) {
  list-style: none;
  padding-left: 0;
}

.list-errors ul.multiline li {
  line-height: 1.5;
}
</style>
