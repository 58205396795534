import http from "@/api/http.js";

const url = "subscriptions";

function fetch(id) {
  return http.get(`${url}/${id}/`);
}

function create(data) {
  return http.post(`${url}/`, data);
}

function fetch_all(options) {
  let query = {
    page: options.page || 1,
    page_size: options.page_size || 5,
  };
  return http.get(`${url}/`, { params: query });
}

export default {
  fetch,
  create,
  fetch_all,
};
