<template>
  <div class="company-dashboard">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="card is-clickable" @click="$router.push({ name: 'shopper-availability-list' })">
          <div class="card-header-title">My Availability</div>
          <div class="card-content">
            <span class="icon is-large">
              <i class="fa fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card is-clickable" @click="$router.push({ name: 'shopper-inbox' })">
          <div class="card-header-title">Notification Center</div>
          <div class="card-content">
            <span class="icon is-large">
              <img src="@/assets/images/menu-icons/inbox_icon.svg" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-vcentered">
      <div class="column">
        <div class="card is-clickable" @click="$router.push({ name: 'shopper-companies' })">
          <div class="card-header-title">Companies</div>
          <div class="card-content">
            <span class="icon is-large">
              <i class="fa fa-store-alt"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card is-clickable" @click="$router.push({ name: 'shopper-profile' })">
          <div class="card-header-title">Settings</div>
          <div class="card-content">
            <span class="icon is-large">
              <i class="fa fa-cog"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
.company-dashboard {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.company-dashboard h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.company-dashboard .columns {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.company-dashboard .columns a {
  display: block;
  text-align: center;
  line-height: 2.5;
}

/* .company-dashboard .columns .column:nth-child(3n + 2) a {
  background-color: var(--light-purple);
} */

.company-dashboard .columns a {
  background-color: var(--light-purple);
}

.company-dashboard .card:hover {
  background-color: var(--light-purple);
}

.company-dashboard table {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.company-dashboard table thead th,
.company-dashboard table tbody td,
.company-dashboard table tfoot td {
  border: none;
}

.company-dashboard table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.company-dashboard table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.company-dashboard table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.company-dashboard table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.company-dashboard .coin {
  width: 16px;
  height: 16px;
}

.company-dashboard .media + .media {
  border-top: none;
}

.company-dashboard .card {
  background-color: var(--light-purple);
}

.company-dashboard .card-content {
  display: flex;
  justify-content: center;
}

.company-dashboard .card:hover {
  box-shadow: none !important;
}

.company-dashboard .card-content .icon i {
  font-size: 8rem;
  color: var(--purple);
}

.company-dashboard .card-content .icon img {
  width: 8rem;
  height: 8rem;
  color: var(--purple);
}

.company-dashboard .card-content .icon.is-large {
  width: 10rem;
  height: 10rem;
}
</style>
