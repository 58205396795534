<template>
  <div id="app">
    <router-view />

    <!-- <div v-show="is_loading" class="content">
      <button class="button is-loading is-large is-ghost">Loading</button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "App",

  components: {},

  props: {},

  data() {
    return {
      // is_loading: true
    };
  },

  computed: {
    is_loading() {
      return this.$store.state.auth.is_loading;
    },
  },

  watch: {},

  created() {},

  methods: {},
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

#app > .content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
