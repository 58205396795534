<template>
  <div class="company-editor container is-centered">
    <div class="block has-text-centered">
      <h1 class="font-poppins">Account</h1>
    </div>

    <div class="block has-text-centered">
      If you need to modify or change your subscription, please
      <br />
      email support@margieslist.com with details.
    </div>

    <div class="columns">
      <h3 class="font-poppins mb-3">Preferences</h3>
      <div class="spacer"></div>
    </div>
    <settings :account-id="auth_user.id" />

    <company-form v-if="auth_user.is_owner" />
    <user-form v-else />

    <company-users v-if="auth_user.is_owner" />

    <billing-details v-if="auth_user.is_owner" />
  </div>
</template>

<script>
import Settings from "@/components/Settings";
import UserForm from "../components/UserForm";
import CompanyForm from "../components/CompanyForm";
import CompanyUsers from "../components/CompanyUsers";
import BillingDetails from "../components/BillingDetails";

export default {
  name: "CompanyEditor",

  components: { BillingDetails, CompanyUsers, CompanyForm, UserForm, Settings },

  props: {},

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
.company-editor {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.company-editor h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.company-editor h3 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;
  font-weight: 700;
  font-size: 20px;
  color: var(--dark-grey);
}

.company-editor .edit-icon {
  margin: 80px auto 0 auto;
  height: 30px;
  width: 30px;
  background-color: var(--light-purple);
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: var(--purple);
}

.company-editor form {
  width: 724px;
}

.company-editor .field .label {
  color: var(--dark-grey);
}

.company-editor .field + .field {
  margin-top: 30px;
}

.company-editor form button.submit {
  width: 100%;
}

.company-editor table thead th,
.company-editor table tbody td,
.company-editor table tfoot td {
  border: none;
}

.company-editor table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.company-editor table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.company-editor table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}
</style>
