import Vue from "vue";
import VCalendar from "v-calendar";

import store from "@/store.js";
import router from "@/router.js";
import App from "@/App.vue";

import PublicLayout from "@/layouts/PublicLayout.vue";
import FieldError from "@/components/FieldError.vue";
import ListErrors from "@/components/ListErrors.vue";
import USStateSelector from "@/components/USStateSelector.vue";
import Paginator from "@/components/Paginator";

import "@/service-worker-register.js";

import "bulma/css/bulma.css";
import "@fortawesome/fontawesome-free/css/all.css";
// import '@fortawesome/fontawesome-free/js/all.js'
import "@/styles/main.css";
import api from "@/api";
import utils from "@/utilities";
import ServerError from "./views/ServerError";
import InfoNotification from "./components/InfoNotification";

window.Vue = Vue;

Vue.config.productionTip = false;

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

// Experimental directive to simplify assigning is-loading class
Vue.directive("is-loading", function (el, binding, vnode) {
  let is_loading = binding.value;

  el.classList.remove("is-loading");

  if (is_loading) {
    el.classList.add("is-loading");
  }
});

Vue.component("public-layout", PublicLayout);
Vue.component("field-error", FieldError);
Vue.component("info-notification", InfoNotification);
Vue.component("list-errors", ListErrors);
Vue.component("USStateSelector", USStateSelector);
Vue.component("paginator", Paginator);

function mount_app() {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
  app.$forceUpdate();
}

function mount_server_error() {
  new Vue({
    render: (h) => h(ServerError),
  }).$mount("#app");
}

api.accounts
  .whoami()
  .then((response) => {
    store.state.auth.user = response.data;
    if (response.data.anonymous) {
      mount_app();
      return;
    }

    utils.account.set_current_user_state(response.data).then(() => {
      mount_app();
    });
  })
  .catch(() => {
    mount_server_error();
  });
