import InboxMessage from "./views/InboxMessage";
import Home from "./views/Home";
import Outbox from "./views/Outbox";
import Inbox from "./views/Inbox";
import OutboxMessage from "./views/OutboxMessage";
import NewMessage from "./views/NewMessage";
import Success from "./views/Success";

const company_mail_routes = [
  {
    path: "outbox",
    meta: { requires_auth: true },
    component: Outbox,
    children: [
      {
        name: "outbox",
        path: "",
        component: Home,
        meta: { requires_auth: true },
      },
      {
        name: "new-message",
        path: "new",
        meta: { requires_auth: true },
        component: NewMessage,
      },
      {
        name: "new-shopper-message",
        path: "new/:shopper_id",
        meta: { requires_auth: true },
        component: NewMessage,
      },
      {
        name: "outbox-send-success",
        path: "success",
        meta: { requires_auth: true },
        component: Success,
      },
      {
        name: "outbox-message",
        path: ":id",
        meta: { requires_auth: true },
        component: OutboxMessage,
      },
    ],
  },
  {
    path: "inbox",
    meta: { requires_auth: true },
    component: Inbox,
    children: [
      {
        name: "company-inbox",
        path: "",
        component: Home,
        meta: { requires_auth: true },
      },
      {
        name: "company-inbox-message",
        path: ":id",
        meta: { requires_auth: true },
        component: InboxMessage,
      },
    ],
  },
];

const shopper_mail_routes = [
  {
    path: "inbox",
    meta: { requires_auth: true },
    component: Inbox,
    children: [
      {
        name: "shopper-inbox",
        path: "",
        component: Home,
        meta: { requires_auth: true },
      },
      {
        name: "shopper-inbox-message",
        path: ":id",
        meta: { requires_auth: true },
        component: InboxMessage,
      },
    ],
  },
];

const mail_routes = {
  company: company_mail_routes,
  shopper: shopper_mail_routes,
};

export default mail_routes;
