<template>
  <div class="dropdown autocomplete" :class="{ 'is-active': suggestions.length > 0 }">
    <input v-model="input_value" :placeholder="placeholder" class="input" type="text" @keyup="on_keyup" />
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          v-for="item in suggestions"
          href="#"
          class="dropdown-item"
          :class="{ 'is-active': selection === item.id }"
          :key="item.id"
          @click.prevent="select_item(item)"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "AutoComplete",
  props: {
    selection: {
      type: String,
      required: true,
      twoWay: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      input_value: "",
      suggestions: [],
    };
  },
  methods: {
    on_keyup: debounce(function () {
      this.$emit("keyup", this.input_value);
    }, 500),

    select_item(item) {
      this.input_value = item.name;
      this.$emit("update:selection", item.id);
      this.suggestions = [];
    },

    set_suggestions(suggestions) {
      this.suggestions = suggestions;
    },
  },
};
</script>

<style>
.autocomplete {
  width: 100%;
}
.autocomplete .dropdown-menu {
  width: 100%;
}
</style>
