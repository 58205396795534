<template>
  <div class="modal" :class="{ 'is-active': show_help }">
    <div class="modal-background" @click="show_help = false"></div>
    <div class="modal-content">
      <div class="card">
        <span class="is-clickable mx-2 my-1 is-pulled-right" aria-label="close" @click.prevent="show_help = false">
          &#10006;
        </span>
        <div class="card-content px-6 py-5">
          <h3 class="is-size-5 has-text-weight-semibold mb-3">Get Help</h3>
          Contact (817) 366-4941 or
          <a href="mailto:team@mysteryshopping.com">team@mysteryshopping.com</a>
          for assistance.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help",

  data() {
    return {
      show_help: false,
    };
  },

  methods: {
    open() {
      this.show_help = true;
    },

    close() {
      this.show_help = false;
    },
  },
};
</script>

<style scoped></style>
