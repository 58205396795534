<template>
  <form>
    <div class="field">
      <label class="checkbox label" :disabled="is_loading">
        <input
          v-model="form.fields.disable_email_notifications"
          type="checkbox"
          @change="submit"
          :disabled="is_loading"
        />
        Disable email notifications
      </label>
      <field-error :message="form.field_errors.display_email_notifications" />
    </div>

    <!-- SUCCESS NOTIFICATION-->
    <div class="field" v-if="display_notification">
      <div class="notification is-success is-light">
        <button class="delete" @click.prevent="display_notification = false"></button>
        Your preferences have been successfully updated.
      </div>
    </div>
  </form>
</template>
<script>
import utils from "@/utilities";
import api from "@/api";

export default {
  name: "Settings",

  props: { accountId: { type: String, required: true } },
  data() {
    return {
      is_loading: false,
      display_notification: false,
      form: {
        fields: {},
        errors: [],
        field_errors: {},
      },
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.accounts.settings
        .update(this.accountId, this.form.fields)
        .then(() => {
          this.display_notification = true;
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    fetch() {
      this.is_loading = true;
      api.accounts.settings
        .fetch(this.accountId)
        .then((response) => {
          this.form.fields = response.data;
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style></style>
