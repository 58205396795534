<template>
  <form @submit.prevent="submit">
    <h3 class="font-poppins">Account Details</h3>

    <div class="field mt-6">
      <label class="label">Name</label>
      <div class="control">
        <input v-model="form.fields.name" class="input" type="text" name="user[name]" />
      </div>
      <field-error :message="form.field_errors.name" />
    </div>

    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input v-model="form.fields.email" class="input" type="text" name="user[email]" />
      </div>
      <field-error :message="form.field_errors.email" />
    </div>

    <div class="field" v-if="display_notification">
      <div class="notification is-success is-light">
        <button class="delete" @click.prevent="display_notification = false"></button>
        Your details have been successfully updated.
      </div>
    </div>

    <div class="field my-6">
      <div class="control">
        <button type="submit" class="button submit is-purple is-medium" :class="{ 'is-loading': is_loading }">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";
export default {
  name: "UserForm",
  data() {
    return {
      is_loading: false,
      display_notification: false,

      form: {
        fields: {
          name: "",
          email: "",
        },

        field_errors: {},
        errors: [],
      },
    };
  },

  mounted() {
    this.fetch();
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit() {
      this.is_loading = true;
      this.reset_validation();

      api.companies.users
        .update(this.auth_user.id, this.form.fields)
        .then((response) => {
          this.form.fields = response.data;
          this.display_notification = true;
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    fetch() {
      this.is_loading = true;
      api.companies.users
        .fetch(this.auth_user.id)
        .then((response) => {
          this.form.fields = response.data;
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
