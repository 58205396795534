<template>
  <div class="tip-jar-list container">
    <!-- SHOPPER SEARCH FORM -->
    <h1 class="font-poppins">Search for a shopper to tip</h1>
    <div class="block">
      <form @submit.prevent="new_search">
        <div class="columns is-centered">
          <div class="column is-two-thirds">
            <div class="field">
              <div class="control">
                <div class="control">
                  <input v-model="filters.name" placeholder="Name" class="input" type="text" name="filters[Name]" />
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <button type="submit" :class="{ 'is-loading': is_loading }" class="button submit is-purple is-fullwidth">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>

    <a v-if="is_dirty" @click.prevent="clear_search">
      <i class="fa fa-ban"></i>
      Clear Search
    </a>

    <!-- SHOPPER SEARCH RESULTS -->
    <div v-if="shopper_list.length < 1" class="block">No Results</div>
    <table v-else class="table is-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone number</th>
          <th>View Profile</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(shopper, index) in shopper_list" :key="index">
          <td>
            {{ shopper.name }}
          </td>
          <td>{{ shopper.email }}</td>
          <td>{{ shopper.phone }}</td>
          <td>
            <a href @click.prevent="open_detail(shopper)">
              <i class="fas fa-eye"></i>
            </a>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>

    <!-- PAGINATION -->
    <div class="columns">
      <div class="column is-offset-5">
        <paginator @update:options="update_page" :options.sync="page_options" />
      </div>
    </div>

    <!-- SHOPPER DETAIL DIALOG-->
    <shopper-detail ref="shopper_detail">
      <template v-slot:action>
        <button class="button is-purple is-fluid" @click.prevent="open_tip_jar">Tip This Shopper</button>
      </template>
    </shopper-detail>

    <!-- TIP JAR FORM DIALOGUE -->
    <div class="modal" :class="{ 'is-active': show_tip_jar }">
      <div class="modal-background" @click="close_tip_jar"></div>
      <div class="modal-content">
        <div class="card">
          <span class="is-clickable mx-2 my-1 is-pulled-right" aria-label="close" @click.prevent="close_tip_jar">
            &#10006;
          </span>
          <div class="card-content px-6">
            <div class="block is-size-4 font-poppins has-text-centered">
              Send a tip to
              <strong>{{ shopper_detail.name }}</strong>
              for a job well done.
            </div>
            <form @submit.prevent="send_tip">
              <div class="block px-4 py-4 tip-form has-text-centered is-align-items-center justify-center">
                <img src="@/assets/images/coin-light.svg" class="is-35x35" />

                <div class="block has-text-weight-bold is-size-4">Tip Jar</div>
                <div class="tile is-ancestor is-align-items-center">
                  <div class="tile is-3-mobile is-justify-content-center is-mobile">
                    <i class="fa fa-minus is-clickable is-purple" @click.stop="tip_jar_form.amount--"></i>
                  </div>
                  <div class="tile is-6-mobile is-justify-content-center is-mobile">
                    <div class="field">
                      <div class="control">
                        <input v-model="tip_jar_form.amount" class="tip-input" />
                      </div>
                    </div>
                  </div>
                  <div class="tile is-3-mobile is-justify-content-center is-mobile">
                    <i class="fa fa-plus is-clickable is-purple" @click.stop="tip_jar_form.amount++"></i>
                  </div>
                </div>
                <div class="tile is-ancestor my-5">
                  <div class="tile is-12 is-justify-content-center">
                    <button class="button is-purple" :class="{ 'is-loading': is_loading }" type="submit">
                      Send Tip
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div class="tile is-ancestor px-4 py-4">
              <div class="tile is-justify-content-center">+1 = average</div>
              <div class="tile is-justify-content-center">+5 = good</div>
              <div class="tile is-justify-content-center">+10 = excellent</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TIP SUCCESS DIALOG -->
    <div class="modal" :class="{ 'is-active': show_tip_success }">
      <div class="modal-background" @click="close_tip_success"></div>
      <div class="modal-content">
        <div class="card">
          <span class="is-clickable mx-2 my-1 is-pulled-right" aria-label="close" @click.prevent="close_tip_success">
            &#10006;
          </span>
          <div class="card-content px-6 py-6 has-text-centered is-justify-content-center">
            <img src="@/assets/images/coin.svg" class="is-101x101" />
            <div class="font-poppins is-size-2 has-text-weight-medium">Thank you!</div>
            <div class="font-poppins is-size-5 has-text-weight-medium mt-4">
              You just sent
              <strong>{{ shopper_detail.name }}</strong>
              <br />
              a generous tip.
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click.prevent="close_tip_success"></button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import initials from "@/utilities/initials";
import ShopperDetail from "../components/ShopperDetail";

const get_filters = function () {
  return {
    name: "",
  };
};

const get_tip_jar_form = function () {
  return {
    recipient: null,
    amount: 0,
  };
};

export default {
  name: "ShopperTipJar",

  components: { ShopperDetail },

  props: {},

  data() {
    return {
      is_loading: false,

      shopper_list: [],

      filters: get_filters(),

      show_tip_jar: false,

      show_tip_success: false,

      tip_jar_form: get_tip_jar_form(),

      shopper_detail: {},

      page_options: {
        page: 1,
        page_size: 10,
        total_items: 0,
      },
    };
  },

  computed: {
    shopper_initials() {
      return initials.get_initials(this.shopper_detail.name);
    },

    is_dirty() {
      return this.filters.name || this.shopper_list.length > 0;
    },
  },

  methods: {
    new_search() {
      this.switch_to_first_page();
      this.search();
    },

    search() {
      this.is_loading = true;

      this.filters.page = this.page_options.page;
      this.filters.page_size = this.page_options.page_size;

      const filters = Object.assign({}, this.filters);

      api.shoppers
        .filter(filters)
        .then((response) => {
          this.shopper_list = response.data.results || [];
          this.page_options.total_items = response.data.count;
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    send_tip() {
      this.is_loading = true;

      api.shoppers.tip
        .send(this.tip_jar_form)
        .then(() => {
          this.open_tip_success();
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    open_detail(details) {
      this.$refs.shopper_detail.open(details);
      this.shopper_detail = details;
    },

    close_detail() {
      this.$refs.shopper_detail.close();
    },

    open_tip_jar() {
      this.tip_jar_form.recipient = this.shopper_detail.id;
      this.$refs.shopper_detail.close();
      this.show_tip_jar = true;
    },

    close_tip_jar() {
      this.show_tip_jar = false;
      this.tip_jar_form = get_tip_jar_form();
    },

    open_tip_success() {
      this.close_tip_jar();
      this.show_tip_success = true;
    },

    close_tip_success() {
      this.shopper_detail = {};
      this.show_tip_success = false;
    },

    update_page() {
      this.search();
    },

    clear_search() {
      this.filters = get_filters();
      this.shopper_list = [];
      this.switch_to_first_page();
    },

    switch_to_first_page() {
      this.page_options.page = 1;
      this.page_options.total_items = 0;
    },
  },
};
</script>

<style>
.tip-jar-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.tip-jar-list h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.tip-jar-list .columns {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.tip-jar-list .columns a {
  display: block;
  text-align: center;
  line-height: 2.5;
}

.tip-jar-list .columns a {
  background-color: var(--light-purple);
}

.tip-jar-list .columns a:hover {
  background-color: var(--purple);
  color: #fff;
}

.tip-jar-list table {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.tip-jar-list table thead th,
.tip-jar-list table tbody td,
.tip-jar-list table tfoot td {
  border: none;
}

.tip-jar-list table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.tip-jar-list table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.tip-jar-list table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.tip-jar-list table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.tip-jar-list .initials {
  height: 100px;
  width: 100px;
  background-color: var(--light-purple);
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: var(--purple);
}

.tip-jar-list .coin {
  width: 16px;
  height: 16px;
}

.tip-jar-list .tip-form {
  background-color: var(--light-purple);
  color: var(--purple);
}

.tip-jar-list .media + .media {
  border-top: none;
}

.tip-jar-list .is-35x35 {
  height: 35px;
  width: 35px;
}

.tip-jar-list .is-101x101 {
  height: 101px;
  width: 101px;
}

.tip-jar-list .tip-input {
  height: 43px;
  width: 92px;
  border: none;
  text-align: center;
  color: var(--purple);
  font-size: xx-large;
  font-weight: bolder;
}

.tip-jar-list .detail-modal {
  max-width: 400px;
}
</style>
