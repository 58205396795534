<template>
  <nav
    v-show="page_count > 1"
    class="pagination is-small is-rounded is-right mb-5"
    role="navigation"
    aria-label="pagination"
  >
    <a class="pagination-previous" @click.prevent="previous" title="Previous" :disabled="options.page === 1">
      <i class="fa fa-chevron-left"></i>
    </a>
    <ul class="pagination-list">
      <li v-for="(n, index) in page_count" :key="index">
        <a
          class="pagination-link"
          :class="{ 'is-current': n === options.page }"
          :aria-label="`Goto page ${n}`"
          @click.prevent="goto(n)"
        >
          {{ n }}
        </a>
      </li>
      <li>
        <a title="Next" class="pagination-next" @click.prevent="next" :disabled="options.page === page_count">
          <i class="fa fa-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Paginator",

  props: { options: { type: Object, required: true, twoWay: true } },

  data() {
    return {};
  },

  computed: {
    page_count() {
      if (this.options.total_items <= this.options.page_size) return 1;
      return Math.ceil(this.options.total_items / this.options.page_size);
    },
  },

  methods: {
    goto(page) {
      this.options.page = page;
      this.update_options();
    },

    next() {
      this.options.page++;
      this.update_options();
    },

    previous() {
      this.options.page--;
      this.update_options();
    },

    first() {
      this.options.page = 1;
      this.update_options();
    },

    last() {
      this.options.page = this.page_count;
      this.update_options();
    },

    update_options() {
      this.$emit("update:options", this.options);
    },
  },
};
</script>

<style scoped></style>
