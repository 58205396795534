import api from "@/api";
import store from "@/store";

function get_default_user_route(user) {
  let default_route_name = user.is_shopper ? "shopper-home" : "company-home";
  return { name: default_route_name };
}

function set_current_user_state(user) {
  let user_id = user.id;
  let is_shopper = user.is_shopper;
  let endpoint = is_shopper ? api.shoppers.fetch(user_id) : api.companies.whoami();

  return endpoint.then((response) => {
    let account_details = response.data;

    if (is_shopper) {
      user.shopper_details = account_details;
    } else {
      user.company_details = account_details;
    }

    store.state.auth.user = user;
    return user;
  });
}

export default {
  set_current_user_state,
  get_default_user_route,
};
