import http from "@/api/http.js";

const inbox_url = "messages/inbox";
const outbox_url = "messages/outbox";

/* INBOX URLS */
function inbox_fetch_all(options) {
  let query = {
    page: options.page || 1,
    page_size: options.page_size || 5,
  };

  return http.get(`${inbox_url}/`, { params: query });
}

function inbox_fetch(id) {
  return http.get(`${inbox_url}/${id}/`);
}

function inbox_update_starred(id, value) {
  return http.put(`${inbox_url}/${id}/`, { starred: value });
}

function inbox_delete(id) {
  return http.delete(`${inbox_url}/${id}/`);
}

function inbox_mark_open(id) {
  return http.post(`${inbox_url}/${id}/open/`);
}

function inbox_unread() {
  return http.get(`${inbox_url}/unread/`);
}

/* OUTBOX URLS */
function outbox_fetch_all(options) {
  let query = {
    page: options.page || 1,
    page_size: options.page_size || 5,
  };

  return http.get(`${outbox_url}/`, { params: query });
}

function outbox_create(data) {
  return http.post(`${outbox_url}/`, data);
}

function outbox_fetch(id) {
  return http.get(`${outbox_url}/${id}/`);
}

function outbox_update_starred(id, value) {
  return http.put(`${outbox_url}/${id}/`, { starred: value });
}

function outbox_delete(id) {
  return http.delete(`${outbox_url}/${id}/`);
}

export default {
  inbox: {
    fetch: inbox_fetch,
    fetch_all: inbox_fetch_all,
    update_starred: inbox_update_starred,
    delete: inbox_delete,
    mark_open: inbox_mark_open,
    unread: inbox_unread,
  },
  outbox: {
    create: outbox_create,
    fetch: outbox_fetch,
    fetch_all: outbox_fetch_all,
    update_starred: outbox_update_starred,
    delete: outbox_delete,
  },
};
