import CompanySubscribe from "./views/CompanySubscribe";
import CompanyEditor from "./views/CompanyEditor";
import ShopperSearch from "./views/ShopperSearch";
import ShopperTipJar from "./views/ShopperTipJar";
import mail_routes from "../mail/routes";
import Mail from "../mail/views/Mail";
import CompanyUserList from "./views/CompanyUserList";
import ShopperDetail from "./views/ShopperDetail";
import AvailabilityList from "../shopper/views/AvailabilityList";
import Home from "./views/Home";

const company_routes = [
  {
    name: "company-home",
    path: "/",
    meta: { requires_auth: true },
    component: Home,
  },
  {
    name: "company-shopper-search",
    path: "search/:shopper_id?",
    meta: { requires_auth: true },
    component: ShopperSearch,
  },
  {
    name: "company-tips",
    path: "tips",
    meta: { requires_auth: true },
    component: ShopperTipJar,
  },
  {
    name: "company-subscribe",
    path: "subscribe/:id?",
    meta: { requires_auth: true },
    component: CompanySubscribe,
  },
  {
    name: "company-profile",
    path: "profile",
    meta: { requires_auth: true },
    component: CompanyEditor,
  },
  {
    path: "mail",
    meta: { requires_auth: true },
    component: Mail,
    children: mail_routes.company,
  },
  {
    name: "company-users",
    path: "users",
    meta: { requires_auth: true },
    component: CompanyUserList,
  },
  {
    name: "company-shopper-detail",
    path: "shopper/:id",
    meta: { requires_auth: true },
    component: ShopperDetail,
  },
  {
    name: "company-shopper-availability",
    path: "availability/:shopper_id?",
    meta: { requires_auth: true },
    component: AvailabilityList,
  },
];

export default company_routes;
