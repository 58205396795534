<template>
  <div class="small-text light-grey-text">No mail selected.</div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped></style>
