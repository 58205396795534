import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/Login.vue";
import LandingPage from "@/views/LandingPage.vue";
import ShopperSignUp from "@/views/ShopperSignUp.vue";
import CompanySignUp from "@/views/CompanySignUp.vue";

import shopper_routes from "./shopper/routes";
import company_routes from "./company/routes";
import MainShopperLayout from "./layouts/MainShopperLayout";
import MainCompanyLayout from "./layouts/MainCompanyLayout";
import NotFound from "@/views/NotFound";
import store from "@/store";
import ServerError from "./views/ServerError";
import ResetPasswordRequest from "./views/ResetPasswordRequest";
import ResetPasswordConfirm from "./views/ResetPasswordConfirm";

Vue.use(VueRouter);

const routes = [
  // PUBLIC ROUTES
  {
    name: "home",
    path: "/",
    redirect: { name: "landing-page" },
    meta: { guest_only: true },
  },

  {
    name: "landing-page",
    path: "/landing",
    component: LandingPage,
    meta: { guest_only: true },
  },

  {
    name: "shopper-sign-up",
    path: "/signup/shopper",
    component: ShopperSignUp,
    meta: { guest_only: true },
  },

  {
    name: "company-sign-up",
    path: "/signup/company",
    component: CompanySignUp,
    meta: { guest_only: true },
  },

  {
    name: "login",
    path: "/login",
    component: Login,
    meta: { guest_only: true },
  },
  {
    path: "/reset-password-request",
    name: "reset-password-request",
    component: ResetPasswordRequest,
    meta: { guest_only: true },
  },
  {
    path: "/reset-password-confirm",
    name: "reset-password-confirm",
    component: ResetPasswordConfirm,
    meta: { guest_only: true },
  },

  // AUTHENTICATED ROUTES
  {
    path: "/shopper",
    component: MainShopperLayout, // Base layout can be customised individually for for Company/Shopper if needed
    meta: { requires_auth: true },
    children: shopper_routes,
  },

  {
    path: "/company",
    component: MainCompanyLayout,
    meta: { requires_auth: true },
    children: company_routes,
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
  {
    path: "/error",
    name: "server-error",
    component: ServerError,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store.state.auth.user;

  // Shoppers receive 404 error when navigating to company routes
  if (to.matched.some((record) => record.path.match("^/company"))) {
    if (user.is_shopper) {
      next({ name: "not-found" });
      return;
    }
  }

  // Companies receive 404 error when navigating to shopper routes
  if (to.matched.some((record) => record.path.match("^/shopper"))) {
    if (user.is_company) {
      next({ name: "not-found" });
      return;
    }
  }

  // Force un-subscribed companies to subscription page
  if (to.matched.some((record) => record.name !== "company-subscribe" && record.meta.requires_auth)) {
    if (to.name !== "company-subscribe" && user.is_company && !user.company_details.is_subscribed) {
      next({ name: "company-subscribe" });
      return;
    }
  }

  // Force unauthenticated users to login
  if (to.matched.some((record) => record.meta.requires_auth)) {
    if (!user.id) {
      next({
        name: "login",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }

  // Force authenticated users to dashboard
  if (to.matched.some((record) => record.meta.guest_only)) {
    if (user.id) {
      const route_name = user.is_shopper ? "shopper-home" : "company-home";
      next({ name: route_name });
      return;
    }
  }

  next();
});

export default router;
