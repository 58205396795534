<template>
  <div class="shopper-detail container">
    <!-- HEADING -->
    <div class="heading">
      <div class="media">
        <div class="media-left">
          <div class="initials is-size-3 has-text-weight-light">
            {{ shopper_initials }}
          </div>
        </div>
        <div class="media-content media-right" style="vertical-align: middle; justify-content: center">
          <h1 class="font-poppins mt-5">{{ shopper.name }}</h1>
        </div>
      </div>
    </div>

    <div class="columns details mb-4">
      <!-- SHOPPER STATS -->
      <div class="column">
        <div class="media border-t-0 mt-0 pt-0">
          <div class="media-left">
            <figure class="image is-24x24">
              <img class="coin" src="@/assets/images/coin.svg" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <span class="has-text-weight-medium">Coins: {{ shopper.coins }}</span>
            </div>
          </div>
        </div>

        <div class="media border-b-0">
          <div class="media-left">
            <span class="icon">
              <static-check-box :value="false" />
              <!-- TODO: plug real data in after connecting good-hire api -->
            </span>
          </div>
          <div class="media-content">
            <div class="content">
              <span class="has-text-weight-medium">Background Check</span>
            </div>
          </div>
        </div>

        <div class="media border-b-0">
          <div class="media-left">
            <static-check-box :value="shopper.has_mystery_shopping_experience" />
          </div>
          <div class="media-content">
            <div class="content">
              <span class="has-text-weight-medium">Mystery Shopping experience</span>
            </div>
          </div>
        </div>

        <div class="media border-b-0">
          <div class="media-left">
            <static-check-box :value="shopper.has_video_shopping_experience" />
          </div>
          <div class="media-content">
            <div class="content">
              <span class="has-text-weight-medium">Video Shopping experience</span>
            </div>
          </div>
        </div>
      </div>
      <!-- COMPANY ACTIONS-->
      <div class="column">
        <router-link class="is-pulled-right" :to="{ name: 'new-shopper-message', params: { shopper_id: shopper.id } }">
          <i class="fas fa-envelope"></i>
          Message this shopper
        </router-link>
        <router-link class="is-pulled-right mt-2" :to="{ name: 'company-shopper-availability', params: { shopper_id: shopper.id } }">
          <i class="fas fa-calendar"></i>
          View shopper availability
        </router-link>
      </div>
    </div>

    <!-- SHOPPER DETAILS -->

    <div class="details">
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input :value="shopper.email" class="input" disabled />
        </div>
      </div>

      <div class="field columns">
        <div class="column py-0">
          <div class="field">
            <label class="label">Date of Birth</label>
            <div class="control">
              <input :value="shopper.date_of_birth" class="input" disabled />
            </div>
          </div>
        </div>

        <div class="column py-0">
          <div class="field">
            <label class="label">Race</label>
            <div class="control is-expanded">
              <RaceSelector :selection.sync="shopper.race" class="is-fullwidth" disabled></RaceSelector>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Address</label>
        <div class="control">
          <input :value="shopper.address" class="input" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">State</label>
        <div class="control">
          <USStateSelector :selection.sync="shopper.state" class="is-fullwidth" disabled></USStateSelector>
        </div>
      </div>

      <div class="field">
        <label class="label">Zipcode</label>
        <div class="control">
          <input :value="shopper.zip_code" class="input" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Phone</label>
        <div class="control">
          <input :value="shopper.phone" class="input" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label">Previous work and certifications.</label>
        <div class="control">
          <textarea
            class="textarea has-fixed-size"
            rows="4"
            :value="shopper.previous_companies_and_certificates"
            disabled
          ></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Types of apps and equipment used</label>
        <div class="control">
          <textarea
            :value="shopper.types_of_equipment_or_apps_used"
            class="textarea has-fixed-size"
            rows="4"
            disabled
          ></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">Equipment</label>
        <div class="control">
          <textarea :value="shopper.equipment_owned" class="textarea has-fixed-size" rows="4" disabled></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RaceSelector from "@/components/RaceSelector.vue";

import api from "@/api";
import initials from "@/utilities/initials";
import StaticCheckBox from "../../components/StaticCheckBox";

export default {
  name: "ShopperDetail",

  components: {
    StaticCheckBox,
    RaceSelector,
  },

  props: {},

  data() {
    return {
      is_loading: false,
      display_success: false,

      shopper: {
        name: "",
        email: "",
        password: "",

        address: "",
        state: "",
        zip_code: "",
        phone: "",

        date_of_birth: "",
        race: "",

        has_mystery_shopping_experience: null,
        has_video_shopping_experience: null,
        previous_companies_and_certificates: "",
        types_of_equipment_or_apps_used: "",
        equipment_owned: "",
      },
    };
  },

  computed: {
    shopper_id() {
      return this.$route.params.id;
    },

    shopper_initials() {
      return initials.get_initials(this.shopper.name);
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.is_loading = true;
      api.shoppers
        .fetch(this.shopper_id)
        .then((response) => {
          this.shopper = response.data;
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.shopper-detail {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-detail .heading h1 {
  width: 724px;
  text-align: left;
  font-weight: 700;
  font-size: 32px;
  height: 100px;
  color: var(--dark-grey);
}

.shopper-detail .heading {
  display: block;
  margin: 80px auto 0 auto;
  width: 724px;
}

.shopper-detail .details {
  width: 724px;
  margin-bottom: 80px;
}

.shopper-detail .field .label {
  color: var(--dark-grey);
}

.shopper-detail .field + .field {
  margin-top: 30px;
}

.shopper-detail form button.submit {
  width: 100%;
}

.shopper-detail .media + .media {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.shopper-detail .initials {
  height: 100px;
  width: 100px;
  background-color: var(--light-purple);
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: var(--purple);
}
</style>
