<template>
  <div class="confirm-registration container">
    <h1 class="font-poppins">Thank you for your registration!</h1>
    <p class="font-poppins">Please check your email and follow the instructions to confirm your account.</p>

    <div class="actions">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmRegistration",
};
</script>

<style>
.confirm-registration {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirm-registration h1 {
  display: block;
  margin: 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 35px;
  color: var(--dark-grey);
}

.confirm-registration p {
  margin-top: 20px;
  max-width: 594px;

  text-align: center;
  font-size: 24px;
  color: var(--light-grey);
}
.confirm-registration .actions {
  margin-top: 40px;
}

.confirm-registration .actions .button {
  width: 200px;
  border-radius: 10px;

  font-family: var(--font-family-poppins);
  font-weight: bold;
}
</style>
