function get_initials(name) {
  if (!name) return "";
  let initials = name.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

  return initials.join("").toUpperCase();
}

export default {
  get_initials,
};
