<template>
  <div class="shopper-availability-editor container">
    <h1 class="font-poppins">Set your location availability</h1>

    <form class="mt-6" @submit.prevent="submit(false)">
      <div class="field">
        <div class="control center-text">
          <label class="checkbox">
            <input type="checkbox" @change="use_home_address($event.target.checked)" />
            Use my home address as my default location
          </label>
        </div>
        <div class="control mt-4 mx-auto center-text" style="width: 400px">
          <p>If you're traveling within the next two weeks, then please set your location and availability.</p>
        </div>
      </div>

      <div class="field">
        <label class="label">Zip Code</label>
        <div class="control">
          <input
            v-model="form.fields.zip_code"
            name="shopper_availability[zip_code]"
            class="input"
            type="text"
            @keyup="verify_zip_code"
          />
        </div>
        <field-error :message="form.field_errors.zip_code" />
      </div>

      <div class="field">
        <label class="label">City</label>
        <div class="control">
          <input v-model="form.fields.city" name="shopper_availability[city]" class="input" type="text" disabled />
        </div>
        <field-error :message="form.field_errors.city" />
      </div>

      <div class="field">
        <label class="label">State</label>
        <div class="control">
          <USStateSelector :selection.sync="form.fields.state" class="is-fullwidth" :disabled="true" ></USStateSelector>
        </div>
        <field-error :message="form.field_errors.state" />
      </div>

      <div class="field">
        <label class="label">How many miles are you willing to travel for a job?</label>
        <div class="control">
          <input
            v-model="form.fields.travel_range"
            name="shopper_availability[travel_range]"
            class="input"
            type="text"
          />
        </div>
        <field-error :message="form.field_errors.travel_range" />
      </div>

      <div class="field">
        <v-date-picker
          mode="date"
          ref="datepicker"
          v-model="date_range"
          :masks="date_picker_config.masks"
          :model-config="date_picker_config.model"
          is-range
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="columns">
              <div class="column py-0">
                <div class="field">
                  <label class="label">Select range</label>
                  <div class="control has-icons-right mb-0">
                    <input
                      name="shopper_availability[start_date]"
                      class="input is-clickable"
                      type="text"
                      :value="inputValue.start"
                      @click="togglePopover"
                      readonly
                    />
                    <span class="icon is-small is-right">
                      <i class="fas fa-calendar-day"></i>
                    </span>
                  </div>
                </div>
              </div>

              <div class="column is-1" style="width: 45px; height: 45px; align-self: flex-end">
                <i class="fas fa-arrow-right"></i>
              </div>

              <div class="column py-0">
                <div class="field">
                  <label class="label" style="color: transparent">.</label>
                  <div class="control has-icons-right mb-0">
                    <input
                      name="shopper_availability[end_date]"
                      class="input is-clickable"
                      type="text"
                      :value="inputValue.end"
                      @click="togglePopover"
                      readonly
                    />
                    <span class="icon is-small is-right">
                      <i class="fas fa-calendar-day"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <field-error :message="form.field_errors.start_date" />
            <field-error v-if="!form.field_errors.start_date" :message="form.field_errors.end_date" />
          </template>
        </v-date-picker>
      </div>

      <div class="field">
        <list-errors :errors="form.errors"></list-errors>
      </div>

      <div class="field" v-if="display_notification">
        <div class="notification is-success is-light">
          <button class="delete" @click="display_notification = false"></button>
          Your availability was added successfuly.
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button type="submit" class="button submit is-purple is-medium" :class="{ 'is-loading': is_loading }">
            Save
          </button>
          <button
            type="button"
            :disabled="is_loading"
            class="button submit is-outlined is-purple is-medium mt-3"
            @click="submit(true)"
          >
            Save and add another availability
          </button>
          <button
            v-if="form.fields.id"
            type="button"
            class="button submit is-outlined is-danger is-medium mt-3"
            :class="{ 'is-loading': is_deleting }"
            @click.prevent="delete_item"
          >
            Delete
          </button>
        </div>
      </div>
    </form>

    <div class="modal" :class="{ 'is-active': display_success_modal }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="card">
          <div class="card-content">
            <div class="content center-text">
              <p class="has-text-success" style="font-size: 18px">Your availability was updated successfuly.</p>
            </div>
          </div>
          <footer class="card-footer">
            <a class="card-footer-item" @click.prevent="close_success_modal">Close</a>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";
import debounce from "lodash/debounce";

function create_fields() {
  return {
    id: null,
    state: "",
    city: "",
    zip_code: "",
    travel_range: "",
    start_date: "",
    end_date: "",
  };
}

export default {
  name: "ShopperAvailabilityEditor",

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,
      display_notification: false,
      display_success_modal: false,
      is_deleting: false,

      form: {
        fields: create_fields(),

        field_errors: {},
        errors: [],
      },

      date_range: {
        start: "",
        end: "",
      },

      date_picker_config: {
        masks: {
          input: "YYYY-MM-DD",
          iso: "YYYY-MM-DD",
        },
        model: {
          type: "string",
          mask: "YYYY-MM-DD",
        },
      },
    };
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },

    is_update() {
      return !!this.form.fields.id;
    },
  },

  watch: {
    $route: {
      handler(to, from) {
        let id = to.params.id;
        if (id) {
          this.fetch_availability(id);
        }
      },

      immediate: true,
    },

    date_range: {
      handler(value) {
        let fields = this.form.fields;

        fields.start_date = value.start;
        fields.end_date = value.end;
      },

      immediate: true,
    },
  },

  mounted() {},

  methods: {
    fetch_availability(id) {
      api.shoppers.availability
        .fetch(id)
        .then((response) => {
          let fields = Object.assign(create_fields(), response.data);

          this.form.fields = fields;
          this.date_range = {
            start: fields.start_date,
            end: fields.end_date,
          };
        })
        .catch((exception) => {
          // console.log(exception);
        })
        .finally(() => {});
    },

    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    reset_form() {
      this.form.fields = create_fields();

      this.$refs.datepicker.inputValues = [];
      this.$refs.datepicker.value.start = "";
      this.$refs.datepicker.value.end = "";

      this.date_range = {
        start: "",
        end: "",
      };
    },

    use_home_address(checked) {
      this.form.fields.zip_code = checked ? this.auth_user.shopper_details.zip_code : "";
      this.verify_zip_code();
    },

    submit(add_another) {
      this.reset_validation();
      this.is_loading = true;

      let is_update = this.is_update;
      let availability = api.shoppers.availability;
      let promise = is_update ? availability.update : availability.create;

      promise(this.form.fields)
        .then((response) => {
          this.reset_form();

          if (add_another) {
            this.show_success();
          } else {
            if (is_update) {
              this.display_success_modal = true;
            } else {
              this.$router.push({ name: "shopper-home" });
            }
          }
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    show_success() {
      this.display_notification = true;

      setTimeout(() => {
        this.display_notification = false;
      }, 5000);
    },

    close_success_modal() {
      this.display_success_modal = false;
      this.$router.push({ name: "shopper-home" });
    },

    delete_item() {
      api.shoppers.availability.delete(this.form.fields.id).then(() => {
        this.$router.push({ name: "shopper-home" });
      });
    },

    verify_zip_code: debounce(function () {
      this.is_loading = true;
      const address = this.form.fields;
      api.zip_codes
        .fetch(address.zip_code)
        .then((response) => {
          address.state = response.data.state;
          address.city = response.data.city;
          this.form.field_errors.zip_code = null;
          this.form.errors = [];
        })
        .catch((e) => {
          utils.form.set_errors(this.form, e, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    }, 500),
  },
};
</script>

<style>
.shopper-availability-editor {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-availability-editor h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.shopper-availability-editor form {
  width: 724px;
  margin-bottom: 80px;
}

.shopper-availability-editor .field .label {
  color: var(--dark-grey);
}

.shopper-availability-editor .field + .field {
  margin-top: 30px;
}

.shopper-availability-editor form button.submit {
  width: 100%;
}

.modal-content .box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);

  color: #4a4a4a;

  display: block;
  padding: 1.25rem;
}
</style>
