import accounts from "./accounts.js";
import shoppers from "./shoppers.js";
import companies from "./companies.js";
import messages from "./mail.js";
import subscriptions from "./subscriptions";
import zip_codes from "./zip_codes";

export default {
  accounts,
  shoppers,
  companies,
  messages,
  subscriptions,
  zip_codes,
};
