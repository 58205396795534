<template>
  <div class="company-user-list container">
    <!-- ADD NEW USER FORM -->
    <h1 class="font-poppins mb-4">{{ is_update ? "Edit" : "Add" }} a User</h1>

    <p v-if="!is_update">Your subscription supports up to 5 users</p>

    <p v-if="form.fields.is_owner">To edit the account manager please visit: Settings -> Account -> Account manager</p>

    <div class="block">
      <div class="columns is-centered">
        <div class="column">
          <div class="field">
            <div class="control">
              <input
                v-model="form.fields.name"
                placeholder="Full Name"
                class="input"
                type="text"
                name="user[Name]"
                :disabled="form.fields.is_owner"
              />
            </div>
            <field-error :message="form.field_errors.name" />
          </div>
        </div>
        <div class="column">
          <div class="field">
            <div class="control">
              <input
                v-model="form.fields.email"
                placeholder="Email"
                class="input"
                type="email"
                name="user[Email]"
                :disabled="form.fields.is_owner"
              />
            </div>
            <field-error :message="form.field_errors.email" />
          </div>
        </div>
        <template v-if="is_update">
          <div class="buttons has-addons">
            <button class="button" @click.prevent="update_user" :disabled="form.fields.is_owner">Save</button>
            <button class="button" @click.prevent="delete_user" :disabled="form.fields.is_owner">Delete</button>
            <button class="button" @click.prevent="close_editor">Cancel</button>
          </div>
        </template>
        <div v-else class="column">
          <button
            type="submit"
            :class="{ 'is-loading': is_loading }"
            class="button submit is-purple is-fullwidth"
            @click.prevent="create_user"
          >
            Add
          </button>
        </div>
      </div>
    </div>

    <div class="field">
      <list-errors :errors="form.errors"></list-errors>
    </div>

    <!-- USER STATE MONITORS-->
    <monitors ref="user_monitors" />

    <!-- USER LIST -->
    <h1 class="mb-4">Current Users</h1>
    <div v-if="user_list.length < 1" class="block">No Users</div>
    <table v-else class="table is-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Edit User</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in user_list" :key="user.id">
          <td>
            {{ user.name }}
          </td>
          <td>{{ user.email }}</td>
          <td>
            <i class="fa fa-pen is-clickable" @click.prevent="open_editor(user)"></i>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>
  </div>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";
import Monitors from "../components/Monitors";

const get_user_form = function () {
  return {
    name: "",
    email: "",
  };
};

export default {
  name: "CompanyUserList",

  components: { Monitors },

  props: {},

  data() {
    return {
      is_loading: false,

      user_list: [],

      edit_user_id: null,

      form: {
        fields: get_user_form(),
        field_errors: {},
        errors: [],
      },

      monitors: [],
    };
  },

  mounted() {
    this.fetch_all();
  },

  computed: {
    user_initials() {
      return utils.initials.get_initials(this.user_detail.name);
    },

    is_update() {
      return !!this.edit_user_id;
    },
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    fetch_all() {
      this.is_loading = true;

      api.companies.users
        .fetch_all()
        .then((response) => {
          this.user_list = response.data.results || [];
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    create_user() {
      this.is_loading = true;
      this.reset_validation();

      api.companies.users
        .create(this.form.fields)
        .then((response) => {
          utils.list.add_item(this.user_list, response.data);
          this.form.fields = get_user_form();
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    update_user() {
      this.is_loading = true;
      this.reset_validation();

      api.companies.users
        .update(this.edit_user_id, this.form.fields)
        .then((response) => {
          this.user_list = utils.list.update_item(this.user_list, response.data);
          this.close_editor();
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    delete_user() {
      this.is_loading = true;

      api.companies.users
        .delete(this.edit_user_id)
        .then(() => {
          utils.list.delete_item(this.user_list, { id: this.edit_user_id });
          this.close_editor();
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    open_editor(user) {
      this.edit_user_id = user.id;
      this.$refs.user_monitors.open(user);
      this.form.fields = Object.assign({}, user);
      this.form.field_errors = {};
    },

    close_editor() {
      this.edit_user_id = null;
      this.$refs.user_monitors.close();
      this.form.fields = get_user_form();
      this.monitors = [];
    },
  },
};
</script>

<style>
.company-user-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.company-user-list h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.company-user-list .columns {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.company-user-list .columns a {
  display: block;
  text-align: center;
  line-height: 2.5;
}

.company-user-list .columns a {
  background-color: var(--light-purple);
}

.company-user-list .columns a:hover {
  background-color: var(--purple);
  color: #fff;
}

.company-user-list table {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.company-user-list table thead th,
.company-user-list table tbody td,
.company-user-list table tfoot td {
  border: none;
}

.company-user-list table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.company-user-list table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.company-user-list table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.company-user-list table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.company-user-list .initials {
  height: 100px;
  width: 100px;
  background-color: var(--light-purple);
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: var(--purple);
}

.company-user-list .coin {
  width: 16px;
  height: 16px;
}

.company-user-list .tip-form {
  background-color: var(--light-purple);
  color: var(--purple);
}

.company-user-list .media + .media {
  border-top: none;
}

.company-user-list .is-35x35 {
  height: 35px;
  width: 35px;
}

.company-user-list .is-101x101 {
  height: 101px;
  width: 101px;
}

.company-user-list .tip-input {
  height: 43px;
  width: 92px;
  border: none;
  text-align: center;
  color: var(--purple);
  font-size: xx-large;
  font-weight: bolder;
}
</style>
