import http from "@/api/http.js";

const url = "companies";
const user_url = "companies/users";
const monitor_url = "companies/monitors";

function fetch(id) {
  return http.get(`${url}/${id}/`);
}

function whoami() {
  return http.get(`${url}/whoami/`);
}

function create(profile) {
  return http.post(`${url}/`, profile);
}

function update(id, profile) {
  return http.patch(`${url}/${id}/`, profile);
}

function users_fetch_all() {
  return http.get(`${user_url}/`);
}

function users_create(data) {
  return http.post(`${user_url}/`, data);
}

function users_update(id, data) {
  return http.patch(`${user_url}/${id}/`, data);
}

function users_fetch(id) {
  return http.get(`${user_url}/${id}/`);
}

function users_delete(id) {
  return http.delete(`${user_url}/${id}/`);
}

function monitors_fetch_all() {
  return http.get(`${user_url}/`);
}

function monitors_create(data) {
  return http.post(`${monitor_url}/`, data);
}

function monitors_update(id, data) {
  return http.patch(`${monitor_url}/${id}/`, data);
}

function monitors_fetch_for_user(user_id) {
  return http.get(`${monitor_url}/${user_id}/user/`);
}

function monitors_delete(id) {
  return http.delete(`${monitor_url}/${id}/`);
}

export default {
  fetch,
  create,
  update,
  whoami,
  monitors: {
    fetch_all: monitors_fetch_all,
    fetch_for_user: monitors_fetch_for_user,
    create: monitors_create,
    update: monitors_update,
    delete: monitors_delete,
  },
  users: {
    fetch: users_fetch,
    fetch_all: users_fetch_all,
    create: users_create,
    update: users_update,
    delete: users_delete,
  },
};
