<template>
  <div>
    <div class="mail columns is-gapless is-multiline mt-6 mb-0">
      <div class="is-4 mail-list column">
        <template v-if="has_messages">
          <article
            class="mail-item"
            v-for="(mail, index) in inbox"
            :class="{ selected: is_selected(mail.id) }"
            :key="index"
          >
            <div
              @click.prevent="$router.push({ name: `${message_route_prefix}-message`, params: { id: mail.id } })"
              class="mail-content"
            >
              <i class="fas fa-circle mr-4" :class="{ 'purple-text': mail.status !== 'opened' }"></i>

              <div class="flex-grow">
                <strong class="sender">{{ mail.sender_name }}</strong>
                <p class="subject light-grey-text">{{ mail.subject }}</p>
              </div>

              <time class="time light-grey-text small-text cursor-default" :title="mail.created_at">
                {{ get_mail_short_date(mail.created_at) }}
              </time>

              <button
                v-is-loading="is_starring[mail.id]"
                class="button starred is-rounded is-ghost"
                @click.stop="toggle_starred(mail)"
              >
                <i
                  v-show="!is_starring[mail.id]"
                  class="icon fa-star"
                  :class="{ fas: mail.starred, far: !mail.starred }"
                ></i>
              </button>
            </div>
          </article>
        </template>
        <div v-else class="small-text light-grey-text">No messages.</div>
      </div>

      <div class="column is-1 divider"></div>

      <div class="column is-7 is-flex">
        <router-view></router-view>
      </div>
    </div>

    <div class="action columns mb-6">
      <div class="column is-4 mt-5">
        <paginator @update:options="update_page" :options.sync="page_options" />
      </div>
      <div class="column is-1"></div>
      <div class="column is-7 mt-4"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import utils from "../../utilities";
import mail_mixin from "@/mixins/mail";

export default {
  name: "Inbox",

  mixins: [mail_mixin],

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,

      inbox: [],

      // e.g.: is_starring[mail.id]
      is_starring: {},

      page_options: {
        page: 1,
        page_size: 5,
        total_items: 0,
      },
    };
  },

  computed: {
    has_messages() {
      return this.inbox.length > 0;
    },
    auth_user() {
      return this.$store.state.auth.user;
    },
    message_route_prefix() {
      return this.auth_user.is_shopper ? "shopper-inbox" : "company-inbox";
    },
  },

  created() {
    utils.event_hub.listen("InboxMessage.star", this.toggle_starred);
    utils.event_hub.listen("InboxMessage.UPDATED", this.update_message);
    utils.event_hub.listen("InboxMessage.DELETED", this.update_page);
  },

  beforeDestroy() {
    utils.event_hub.unlisten("InboxMessage.star", this.toggle_starred);
    utils.event_hub.unlisten("InboxMessage.UPDATED", this.update_message);
    utils.event_hub.unlisten("InboxMessage.DELETED", this.update_page);
  },

  mounted() {
    this.fetch_inbox();
  },

  methods: {
    fetch_inbox() {
      let query = {
        page: this.page_options.page,
        page_size: this.page_options.page_size,
      };

      this.is_loading = true;

      api.messages.inbox
        .fetch_all(query)
        .then((response) => {
          this.inbox = response.data.results || [];
          this.page_options.total_items = response.data.count;
          this.update_unread_message_count();
        })
        .catch((exception) => {
          console.log(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    toggle_starred(mail) {
      let id = mail.id;
      this.$set(this.is_starring, id, true);

      api.messages.inbox
        .update_starred(id, !mail.starred)
        .then((response) => {
          mail.starred = response.data.starred;
        })
        .catch(() => {
          // nothing to do, for now
          // maybe add an error message right under the
        })
        .finally(() => {
          this.is_starring[id] = false;
        });
    },

    update_page() {
      this.fetch_inbox();
    },

    update_message(message) {
      this.inbox = utils.list.update_item(this.inbox, message);
    },
  },
};
</script>

<style></style>
