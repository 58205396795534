<template>
  <div v-if="user.id" class="user-monitors">
    <h1 class="font-poppins mb-4">Monitors</h1>
    <p>Add states for {{ user.name }} to monitor for shopper activity.</p>
    <div class="columns">
      <div class="column col-lg-4">
        <USStateSelector
          ref="state_selector"
          @update:selection="add_state_monitor"
          class="is-fullwidth"
        ></USStateSelector>
      </div>
      <div class="column col-lg-8">
        <div class="tags are-medium">
          <span v-for="monitor in monitors" class="tag is-purple" :key="monitor.id">
            {{ monitor.state }}
            <button class="delete is-small" @click.stop="delete_monitor(monitor.id)" title="Remove"></button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";

export default {
  name: "Monitors",

  data() {
    return {
      monitors: [],
      user: {},
    };
  },

  methods: {
    fetch_user_monitors() {
      this.is_loading = true;

      api.companies.monitors
        .fetch_for_user(this.user.id)
        .then((response) => {
          this.monitors = response.data;
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    add_state_monitor(selection) {
      if (selection) {
        this.is_loading = true;
        api.companies.monitors
          .create({ state: selection, owner_id: this.user.id })
          .then((response) => {
            utils.list.add_item(this.monitors, response.data);
          })
          .catch((exception) => {
            console.error(exception);
          })
          .finally(() => {
            this.is_loading = false;
            this.$refs.state_selector.clear();
          });
      }
    },

    delete_monitor(monitor_id) {
      this.is_loading = true;

      api.companies.monitors
        .delete(monitor_id)
        .then(() => {
          utils.list.delete_item(this.monitors, { id: monitor_id });
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    open(user) {
      this.user = user;
      this.fetch_user_monitors();
    },

    close() {
      this.user = {};
      this.monitors = [];
    },
  },
};
</script>

<style>
.user-monitors {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.user-monitors .tag {
  background-color: var(--light-purple);
  color: var(--purple);
}
</style>
