<template>
  <public-layout>
    <!-- RESET FORM -->
    <div v-if="!show_message" class="password-reset container">
      <h1 class="font-poppins mb-5">Reset Password</h1>
      <form @submit.prevent="submit">
        <div class="field mb-5">
          <div class="control">
            <input
              name="PasswordReset[Email]"
              class="input"
              type="text"
              v-model="form.fields.email"
              :disabled="is_loading"
              placeholder="Email"
              required
            />
          </div>
          <field-error :message="form.field_errors.email" />
        </div>
        <div class="field">
          <list-errors :errors="form.errors"></list-errors>
        </div>

        <div class="field mt-4">
          <div class="control">
            <button type="submit" class="button submit is-purple is-medium fill-width" v-is-loading="is_loading">
              RESET
            </button>
          </div>
        </div>
      </form>

      <div class="my-6">
        <div class="center-text" v-show="!is_loading">
          <router-link class="purple-text mr-4" :to="{ name: 'login' }">Back to Login</router-link>
        </div>
      </div>
    </div>
    <!-- POST RESET MESSAGE -->
    <div v-else class="password-reset container">
      <h1 class="font-poppins mb-5">Request Sent</h1>
      <p>We have sent you an email with a link to reset your password.</p>
      <p>
        Didn't receive the email yet? Please check your spam folder, or
        <a href @click.prevent="reset_form">resend</a>
        the email.
      </p>
    </div>
  </public-layout>
</template>

<script>
import { set_errors } from "@/utilities/form";
import api from "@/api";

export default {
  name: "ResetPasswordRequest",
  data() {
    return {
      is_loading: false,
      show_message: false,

      form: {
        fields: {
          email: "",
        },
        errors: [],
        field_errors: {},
      },
    };
  },

  methods: {
    reset_form() {
      this.form.fields.email = "";
      this.form.field_errors = {};
      this.form.errors = [];
      this.show_message = false;
    },

    submit() {
      this.is_loading = true;

      api.accounts
        .request_password_reset(this.form.fields)
        .then(() => {
          this.show_message = true;
        })
        .catch((error) => {
          set_errors(this.form, error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.password-reset.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.password-reset.container h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.password-reset.container form {
  width: 400px;
}
</style>
