<template>
  <div class="shopper-availability-list container">
    <div class="columns">
      <h1 class="font-poppins">{{ page_heading }}</h1>
      <div class="spacer" />
      <router-link v-if="is_shopper" :to="{ name: 'shopper-availability-add' }" class="button is-purple">
        Add Availability
      </router-link>
    </div>

    <table class="table is-striped">
      <thead>
        <tr>
          <th>Dates</th>
          <th>State</th>
          <th>City</th>
          <th>Zip Code</th>
          <th>Miles will travel</th>
          <th v-if="is_shopper"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(availability, index) in availability_list" :key="index">
          <td>
            <span class="start-date">{{ availability.start_date }}</span>
            <i class="fas fa-arrow-right mx-2"></i>
            {{ availability.end_date }}
          </td>
          <td>{{ availability.state }}</td>
          <td>{{ availability.city }}</td>
          <td>{{ availability.zip_code }}</td>
          <td>{{ availability.travel_range }}</td>
          <td v-if="is_shopper">
            <router-link :to="{ name: 'shopper-availability-edit', params: { id: availability.id } }">Edit</router-link>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">
            <div class="spacer"></div>
            <paginator @update:options="update_page" :options.sync="page_options" />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ShopperAvailabilityList",

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,

      shopper: {},

      availability_list: [],

      page_options: {
        page: 1,
        page_size: 10,
        total_items: 0,
      },
    };
  },

  computed: {
    shopper_id() {
      return this.$route.params.shopper_id;
    },
    is_shopper() {
      return !this.shopper_id;
    },
    page_heading() {
      return this.is_shopper ? "My Availability" : `${this.shopper.name}'s Schedule`;
    },
  },

  mounted() {
    this.fetch_list();
    if (!this.is_shopper) this.fetch_shopper();
  },

  methods: {
    fetch_list() {
      this.is_loading = true;

      let query = {
        page: this.page_options.page,
        page_size: this.page_options.page_size,
      };

      if (this.shopper_id) query.shopper_id = this.shopper_id;

      api.shoppers.availability
        .fetch_all(query)
        .then((response) => {
          let list = response.data.results || [];
          let simplify_dates = (availability) => {
            availability.start_date = availability.start_date.substring(5).replace("-", "/");
            availability.end_date = availability.end_date ? availability.end_date.substring(5).replace("-", "/") : "";
          };

          list.forEach(simplify_dates);

          this.availability_list = list;
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    fetch_shopper() {
      this.is_loading = true;
      api.shoppers
        .fetch(this.shopper_id)
        .then((response) => {
          this.shopper = response.data;
        })
        .catch((exception) => {
          console.error(exception);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    update_page() {
      this.fetch_list();
    },
  },
};
</script>

<style>
.shopper-availability-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-availability-list h1 {
  width: 724px;
  display: block;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.shopper-availability-list table {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.shopper-availability-list .columns {
  width: 824px;
  margin: 80px auto 0 auto;
}

.shopper-availability-list table thead th,
.shopper-availability-list table tbody td,
.shopper-availability-list table tfoot td {
  border: none;
}

.shopper-availability-list table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.shopper-availability-list table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.shopper-availability-list table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.shopper-availability-list table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.shopper-availability-list table tfoot .button {
  width: 200px;
}
</style>
