import http from "@/api/http.js";

const url = "zip-codes";

function fetch(zip_code) {
  return http.get(`${url}/${zip_code}/`);
}

function fetch_public(zip_code) {
  return http.get(`${url}/public/${zip_code}/`);
}

export default {
  fetch: fetch,
  fetch_public: fetch_public,
};
