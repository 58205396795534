<template>
  <div class="company-subscription-editor container">
    <h1 class="font-poppins">Join Margie's List</h1>

    <div class="control mt-4 mx-auto center-text" style="width: 400px">
      <p class="purple-text">Add your company to Margie's for only $150/month.</p>
    </div>

    <form class="mt-6" @submit.prevent="submit()">
      <div class="field">
        <label class="label">Name on card</label>
        <div class="control">
          <input
            v-model="form.fields.cardholder_name"
            name="company_subscribe[cardholder_name]"
            class="input"
            type="text"
          />
        </div>
        <field-error :message="form.field_errors.cardholder_name" />
      </div>

      <div class="field">
        <label class="label">CC#</label>
        <div class="control">
          <input
            v-model="form.fields.card_number"
            name="company_subscribe[card_number]"
            class="input"
            type="text"
            @keyup="set_card_type"
            :rules="form.rules.card_number"
          />
        </div>
        <field-error :message="form.field_errors.card_number" />
      </div>

      <div class="field">
        <label class="label">CSV</label>
        <div class="control">
          <input
            v-model="form.fields.cvv"
            name="company_subscribe[cvv]"
            class="input"
            type="text"
            :rules="form.rules.cvv"
          />
        </div>
        <field-error :message="form.field_errors.cvv" />
      </div>

      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Exp Month</label>
            <div class="control">
              <input
                v-model="form.fields.exp_month"
                name="company_subscribe[exp_month]"
                class="input"
                type="text"
                :rules="form.rules.exp_month"
              />
            </div>
            <field-error :message="form.field_errors.exp_month" />
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label class="label">Exp Year</label>
            <div class="control">
              <input
                v-model="form.fields.exp_year"
                name="company_subscribe[exp_year]"
                class="input"
                type="text"
                :rules="form.rules.exp_year"
              />
            </div>
            <field-error :message="form.field_errors.exp_year" />
          </div>
        </div>
      </div>

      <div class="field" v-if="display_notification">
        <div class="notification is-success is-light">
          <button class="delete" @click="display_notification = false"></button>
          You have successfully subscribed to Margie's list. Please wait while we redirect you...
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button
            type="submit"
            class="button submit is-purple is-medium"
            :class="{ 'is-loading': is_loading }"
            :disabled="is_loading"
          >
            Subscribe
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";

function create_fields() {
  return process.env.NODE_ENV !== "production"
    ? {
        description: "Emergency Card",
        card_type: "Visa",
        cardholder_name: "Rick Sanchez",
        card_number: "4012881888818888",
        exp_month: "03",
        exp_year: "2025",
        cvv: "999",
      }
    : {
        card_type: "Visa",
        description: "Margie's List Card",
        cardholder_name: "",
        card_number: "",
        exp_month: "",
        exp_year: "",
        cvv: "",
      };
}

function create_model_rules() {
  return {
    cardholder_name: [
      (value) => {
        return !!value || "Name on card is required.";
      },
    ],

    card_number: [
      (value) => {
        return !!value || "Card number is required.";
      },
    ],

    description: [
      (value) => {
        return !!value || "Card description is required.";
      },
    ],

    exp_month: [
      (value) => {
        return !!value || "Please select the expiration month of you card.";
      },
    ],

    exp_year: [
      (value) => {
        return !!value || "Please select the expiration year of you card.";
      },
    ],

    cvv: [
      (value) => {
        return !!value || "CVV code is required.";
      },
    ],
  };
}

function detect_card_type(number) {
  const re = {
    Electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    Maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    Dankort: /^(5019)\d+$/,
    Interpayment: /^(636)\d+$/,
    Unionpay: /^(62|88)\d+$/,
    Visa: /^4[0-9]{0,}$/,
    MasterCard: /^5[1-5][0-9]{14}$/,
    "American Express": /^3[47][0-9]{13}$/, //American Express
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
}

export default {
  name: "CompanySubscribe",

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,
      display_notification: false,
      form: {
        fields: create_fields(),
        field_errors: {},
        errors: [],
        rules: create_model_rules(),
      },
    };
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {},

  mounted() {
    if (this.$route.params.id) {
      this.fetch_subscription();
    }
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    reset_form() {
      this.form.fields = create_fields();
    },

    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.subscriptions
        .create(this.form.fields)
        .then((response) => {
          this.reset_form();
          this.auth_user.company_details.subscription_id = response.data.id;
          this.show_success();
        })
        .catch((exception) => {
          let generic_error = {
            message: "There was an issue performing this action, if this persist please contact us.",
            type: "error",
          };
          utils.form.set_errors(this.form, exception, generic_error);
          this.is_loading = false;
        });
    },

    show_success() {
      this.is_loading = true;
      this.display_notification = true;
      this.auth_user.company_details.is_subscribed = true;

      setTimeout(() => {
        this.display_notification = false;
        this.$router.push({ name: "company-home" });
      }, 2000);
    },

    set_card_type() {
      this.form.data.card_type = detect_card_type(this.form.fields.card_number.trim());
    },

    fetch_subscription() {
      this.is_loading = true;
      api.subscriptions
        .fetch(this.$route.params.id)
        .then((response) => {
          this.form.fields = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.company-subscription-editor {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.company-subscription-editor h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.company-subscription-editor form {
  width: 724px;
  margin-bottom: 80px;
}

.company-subscription-editor .field .label {
  color: var(--dark-grey);
}

.company-subscription-editor .field + .field {
  margin-top: 30px;
}

.company-subscription-editor form button.submit {
  width: 100%;
}
</style>
