import Vue from "vue";

export const event_hub = new Vue({
  methods: {
    trigger(command_name, payload) {
      return this.$emit(command_name, payload);
    },

    /**
     * Listen for events
     * @param {string} event_name
     * @param {function} callback
     * @param {boolean} [duplicate=false] Allow to duplicate the registry of this handler
     */
    listen(event_name, callback, duplicate) {
      if (!duplicate) {
        this.$off(event_name, callback);
      }

      return this.$on(event_name, callback);
    },

    unlisten(event_name, callback) {
      return this.$off(event_name, callback);
    }
  }
});

/**
 * EVENT PATTERNS

- Commands
- Events

Commands are an instruction, telling a system to "do something".
Update local settings, expand/collapse main menu, etc.
Commands can be validated, processed, replied.

Events on the other hand reflect an immutable fact.
MainMenu Expanded. Invoice Created.
We can't validate or stop the processing of past events.

Command names: [ComponentName].[Action in present tense]
- event_hub.trigger("MainMenu.toggle", {optional payload});

Event names: [ComponentName].[Action in past tense]
- event_hub.listen("MainMenu.EXPANDED", event_handler)
- event_hub.listen("MainMenu.COLLAPSED", event_handler)

Note:
For the time being event_hub.trigger() works, but app.events.trigger() might make more sense semantic wise.

 */

export default event_hub;
