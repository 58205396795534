<template>
  <div class="shopper-company-list container">
    <h1 class="font-poppins">Company Database</h1>

    <div class="columns is-multiline is-mobile">
      <template v-for="(company, index) in company_list">
        <div :key="index" class="column is-one-third">
          <a :href="company.website" target="_blank">{{ company.name }}</a>
        </div>
      </template>
    </div>

    <!-- PAGINATION -->
    <div class="columns">
      <div class="column is-offset-5">
        <paginator @update:options="update_page" :options.sync="page_options" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ShopperCompanyList",

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,

      company_list: [],

      page_options: {
        page: 1,
        page_size: 24,
        total_items: 0,
      },
    };
  },

  computed: {},

  mounted() {
    this.fetch_list();
  },

  methods: {
    fetch_list() {
      this.is_loading = true;

      let query = {
        page: this.page_options.page,
        page_size: this.page_options.page_size,
      };

      api.subscriptions
        .fetch_all(query)
        .then((response) => {
          this.company_list = response.data.results || [];
          this.page_options.total_items = response.data.count;
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    update_page() {
      this.fetch_list();
    },
  },
};
</script>

<style>
.shopper-company-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-company-list h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.shopper-company-list .columns {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.shopper-company-list .columns a {
  display: block;
  text-align: center;
  line-height: 2.5;
}

/* .shopper-company-list .columns .column:nth-child(3n + 2) a {
  background-color: var(--light-purple);
} */

.shopper-company-list .columns a {
  background-color: var(--light-purple);
}

.shopper-company-list .columns a:hover {
  background-color: var(--purple);
  color: #fff;
}
</style>
