<template>
  <div class="company-users">
    <div class="columns">
      <h3 class="font-poppins">Users</h3>
      <div class="spacer"></div>
      <span class="edit-icon">
        <i
          class="fa fa-pen is-clickable"
          @click.prevent="
            $router.push({
              name: 'company-users',
            })
          "
        ></i>
      </span>
    </div>

    <table v-if="users.length > 0">
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="text-grey">No Users</div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "CompanyUsers",

  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
    };
  },

  methods: {
    fetch() {
      this.is_loading = true;

      api.companies.users
        .fetch_all()
        .then((response) => {
          this.users = response.data.results || [];
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.company-users table {
  width: 724px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.company-users table thead th,
.company-users table tbody td,
.company-users table tfoot td {
  border: none;
}

.company-users table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.company-users table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.company-users table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.company-users table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.company-users table tfoot .button {
  width: 200px;
}
</style>
