<template>
  <div class="public-layout is-flex is-flex-direction-column fill-height">
    <!-- APP HEADER -->
    <header class="app-header">
      <div class="container is-flex is-justify-content-space-between is-align-items-center fill-height">
        <div class="logo">
          <router-link :to="{ name: 'home' }" class="font-fredoka purple-text is-size-2">Margie’s List</router-link>
          <div class="font-poppins bold-font is-size-7">BY MYSTERYSHOPPING.COM</div>
        </div>

        <div class="spacer"></div>

        <router-link
          :to="{ name: 'login' }"
          class="login button is-ghost transparent-bg text-decoration-none"
        >
          <span class="mr-2">LOGIN</span>
          <i class="fas fa-sign-in-alt"></i>
        </router-link>
      </div>
    </header>

    <section>
      <slot />
    </section>

    <footer>
      <div class="copyright container">© Copyright MysteryShopping.com, 2021</div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PublicLayout",
  components: {},
  computed: {}
};
</script>

<style>
.public-layout {
  background-color: #fff;
  /* background-color: red; */
  flex: 1;
}

.public-layout > header {
  height: 138px;
  background-color: var(--light-purple);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.public-layout > section {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.public-layout > footer {
  height: 100px;
  background-color: var(--light-purple);
}

.public-layout > header .login.button {
  font-family: var(--font-family-fredoka);
  font-size: var(--font-size-m);
  color: var(--purple);
}

.public-layout > footer .copyright {
  min-height: 100%;
  color: var(--light-grey);

  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
</style>
