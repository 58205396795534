import AvailabilityList from "@/shopper/views/AvailabilityList.vue";
import AvailabilityEditor from "@/shopper/views/AvailabilityEditor.vue";

import CompanyList from "@/shopper/views/CompanyList.vue";
import Mail from "../mail/views/Mail";
import mail_routes from "../mail/routes";
import ShopperEditor from "./views/ShopperEditor";
import Home from "./views/Home";

const shopper_routes = [
  {
    name: "shopper-home",
    path: "",
    meta: { requires_auth: true },
    component: Home,
  },

  {
    name: "shopper-availability-list",
    path: "",
    meta: { requires_auth: true },
    component: AvailabilityList,
  },

  {
    name: "shopper-availability-add",
    path: "availability/new",
    meta: { requires_auth: true },
    component: AvailabilityEditor,
  },

  {
    name: "shopper-availability-edit",
    path: "availability/:id",
    meta: { requires_auth: true },
    component: AvailabilityEditor,
  },

  {
    name: "shopper-companies",
    path: "companies",
    meta: { requires_auth: true },
    component: CompanyList,
  },

  {
    name: "shopper-profile",
    path: "profile",
    meta: { requires_auth: true },
    component: ShopperEditor,
  },

  {
    path: "mail",
    meta: { requires_auth: true },
    component: Mail,
    children: mail_routes.shopper,
  },
];

export default shopper_routes;
