<template>
  <public-layout>
    <div v-if="!display_confirm_registration" class="company-sign-up container">
      <h1 class="font-poppins">Create a Company Account</h1>

      <form @submit.prevent="submit">
        <div class="field mt-6">
          <label class="label">Company Name</label>
          <div class="control">
            <input v-model="form.fields.company_name" class="input" type="text" name="company[company_name]" />
          </div>
          <field-error :message="form.field_errors.company_name" />
        </div>

        <div class="field">
          <label class="label">Address</label>
          <div class="control">
            <input v-model="form.fields.address" class="input" type="text" name="company[Address]" />
          </div>
          <field-error :message="form.field_errors.address" />
        </div>

        <div class="field">
          <label class="label">State</label>
          <div class="control">
            <USStateSelector :selection.sync="form.fields.state" class="is-fullwidth"></USStateSelector>
          </div>
          <field-error :message="form.field_errors.state" />
        </div>

        <div class="field">
          <label class="label">Zipcode</label>
          <div class="control">
            <input v-model="form.fields.zip_code" class="input" type="text" name="company[Zipcode]" />
          </div>
          <field-error :message="form.field_errors.zip_code" />
        </div>

        <h1 class="font-poppins">Account Manager</h1>

        <div class="field mt-6">
          <label class="label">Name</label>
          <div class="control">
            <input v-model="form.fields.user_name" class="input" type="text" name="company[user_name]" />
          </div>
          <field-error :message="form.field_errors.user_name" />
        </div>

        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input v-model="form.fields.email" class="input" type="email" name="company[email]" />
          </div>
          <field-error :message="form.field_errors.email" />
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input v-model="form.fields.password" class="input" type="password" name="company[password]" />
          </div>
          <field-error :message="form.field_errors.password" />
        </div>

        <div class="field my-6">
          <div class="control">
            <button type="submit" class="button submit is-purple is-medium" :class="{ 'is-loading': is_loading }">
              Register
            </button>
          </div>
        </div>
      </form>
    </div>

    <ConfirmRegistration v-if="display_confirm_registration">
      <router-link :to="{ name: 'login' }" class="button is-purple">Log in</router-link>
    </ConfirmRegistration>
  </public-layout>
</template>

<script>
import ConfirmRegistration from "@/components/ConfirmRegistration.vue";

import api from "@/api";
import utils from "@/utilities";

import form from "@/utilities/form";

export default {
  name: "CompanySignUp",

  components: {
    ConfirmRegistration,
  },

  props: {},

  data() {
    return {
      is_loading: false,
      display_confirm_registration: false,

      form: {
        fields: {
          company_name: "",
          user_name: "",
          email: "",
          password: "",
          address: "",
          state: "",
          zip_code: "",
        },

        field_errors: {},
        errors: [],
      },
    };
  },

  computed: {},

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    get_account_details(user) {
      let user_id = user.id;

      return api.companies.fetch(user_id).then((response) => {
        user.company_details = response.data;
        this.set_user_and_redirect(user);
      });
    },

    set_user_and_redirect(user) {
      this.$store.state.auth.user = user;
      this.$router.push({ name: "company-subscribe" });
    },

    // Authenticate user
    login() {
      this.reset_validation();
      this.is_loading = true;

      api.accounts
        .login({ email: this.form.fields.email, password: this.form.fields.password })
        .then((response) => {
          this.get_account_details(response.data);
        })
        .catch((exception) => {
          this.is_loading = false;
          utils.form.set_errors(this.form, exception, form.generic_error);
        });
    },

    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.companies
        .create(this.form.fields)
        .then(() => {
          this.login();
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style>
.company-sign-up {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.company-sign-up h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.company-sign-up form {
  width: 724px;
}

.company-sign-up .field .label {
  color: var(--dark-grey);
}

.company-sign-up .field + .field {
  margin-top: 30px;
}

.company-sign-up form button.submit {
  width: 100%;
}
</style>
