<template>
  <public-layout>
    <div class="login container">
      <h1 class="font-poppins">Login</h1>

      <form @submit.prevent="submit">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input name="Shopper[Email]" class="input" type="text" v-model="form.fields.email" :disabled="is_loading" />
          </div>
          <field-error :message="form.field_errors.email" />
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input
              name="Shopper[Password]"
              class="input"
              type="password"
              v-model="form.fields.password"
              :disabled="is_loading"
            />
          </div>
          <field-error :message="form.field_errors.password" />
        </div>

        <div class="field">
          <list-errors :errors="form.errors"></list-errors>
        </div>

        <div class="field mt-4">
          <div class="control">
            <button type="submit" class="button submit is-purple is-medium fill-width" v-is-loading="is_loading">
              LOGIN
            </button>
          </div>
        </div>
      </form>

      <div class="my-6">
        <div class="center-text" v-show="!is_loading">
          <router-link class="purple-text mr-4" :to="{ name: 'reset-password-request' }">Forgot password?</router-link>

          <router-link class="purple-text" :to="{ name: 'landing-page' }">Don't have an account?</router-link>
        </div>
      </div>
    </div>
  </public-layout>
</template>

<script>
import api from "@/api";
import utils from "@/utilities";

const generic_error = {
  message: "There was an issue performing this action, if this persist please contact us.",
  type: "error",
};

export default {
  name: "Login",

  components: {},

  props: {},

  data() {
    return {
      is_loading: false,

      form: {
        fields: {
          email: "",
          password: "",
        },

        field_errors: {},
        errors: [],
      },
    };
  },

  computed: {
    state_auth() {
      return this.$store.state.auth;
    },
  },

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    // Authenticate user
    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.accounts
        .login(this.form.fields)
        .then((response) => {
          // is_loading isn't set here because some flickering would happen
          utils.account.set_current_user_state(response.data).then(() => {
            const default_route = utils.account.get_default_user_route(response.data);
            const redirect_route = this.$route.query.redirect;
            this.$router.push(redirect_route || default_route);
          });
        })
        .catch((exception) => {
          console.log(exception);
          this.is_loading = false;
          utils.form.set_errors(this.form, exception, generic_error);
        });
    },
  },
};
</script>

<style>
.login.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.login.container h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.login.container form {
  width: 400px;
}
</style>
