<template>
  <article class="mail-reader">
    <div class="envelope">
      <div class="mail-actions is-flex">
        <div class="spacer"></div>

        <!-- STAR MAIL -->
        <button
          class="button is-small is-text is-rounded text-decoration-none mr-2"
          v-is-loading="is_starring[message.id]"
          @click.prevent=""
        >
          <i
            v-show="!is_starring[message.id]"
            class="icon fa-star"
            :class="{ fas: message.starred, far: !message.starred }"
          ></i>
        </button>

        <!-- DELETE MAIL -->
        <button
          v-is-loading="is_deleting"
          @click="display_delete_modal = true"
          class="button is-small is-text is-rounded text-decoration-none"
        >
          <i v-show="!is_deleting" class="far fa-trash-alt icon"></i>
        </button>
      </div>

      <div class="mail-header mt-6">
        <div class="is-flex justify-space-between">
          <div class="sender-name flex-grow">{{ message.sender_name }}</div>

          <time class="time small-text cursor-default">
            {{ get_mail_long_date(message.created_at) }}
          </time>
        </div>

        <div class="flex-grow small-text is-bold mt-5">Subject: {{ message.subject }}</div>
      </div>

      <div class="mail-body mt-5">
        {{ message.body }}
      </div>
    </div>

    <!-- DELETE CONFIRMATION -->
    <div class="modal" :class="{ 'is-active': display_delete_modal }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="card">
          <div class="card-content">
            <div class="content center-text">
              <p class="my-5" style="font-size: 18px">Are you sure you want to delete this message?.</p>
            </div>
          </div>
          <footer class="card-footer">
            <a @click="display_delete_modal = false" class="card-footer-item has-text-black">Cancel</a>
            <a @click="delete_mail(message.id)" class="card-footer-item has-text-danger is-bold">Yes</a>
          </footer>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import api from "@/api";
import mail_mixin from "@/mixins/mail";
import utils from "@/utilities";

export default {
  name: "OutboxMessage",

  mixins: [mail_mixin],

  data() {
    return {
      message: {},
      display_delete_modal: false,
      is_loading: false,
      is_starring: {},
      is_deleting: false,
      event_hub: utils.event_hub,
    };
  },

  mounted() {
    this.fetch();
  },

  watch: {
    message_id() {
      this.fetch();
    },
  },

  computed: {
    message_id() {
      return this.$route.params.id;
    },
  },

  methods: {
    fetch() {
      this.is_loading = true;
      api.messages.outbox
        .fetch(this.message_id)
        .then((response) => {
          this.message = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    delete_mail(id) {
      this.display_delete_modal = false;
      this.is_deleting = true;

      api.messages.outbox
        .delete(id)
        .then(() => {
          utils.event_hub.trigger("OutboxMessage.DELETED", id);
          this.$router.push({ name: "outbox" });
        })
        .catch(() => {
          // nothing to do
        })
        .finally(() => {
          this.is_deleting = false;
        });
    },
  },
};
</script>

<style scoped></style>
