<template>
  <p v-if="message" class="help is-danger">{{message}}</p>
</template>

<script>
export default {
  name: "FieldError",

  components: {},

  props: {
    message: { type: String, default: "" },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {}
}
</script>

<style></style>