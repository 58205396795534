let intl_short_time = new Intl.DateTimeFormat("en-US", { timeStyle: "short" });
let intl_short_date = new Intl.DateTimeFormat("en-US", { day: "2-digit", month: "short" });
let intl_full_date = new Intl.DateTimeFormat("en-US", { dateStyle: "full", timeStyle: "short" });
import util from "@/utilities";
import api from "@/api";

const mail_mixin = {
  data() {
    return {
      selected_mail: null,
    };
  },
  methods: {
    get_mail_short_date(iso_date) {
      if (!iso_date) return "";
      let mail_date = new Date(iso_date);

      if (util.date.is_today(mail_date)) {
        return intl_short_time.format(mail_date);
      }

      return intl_short_date.format(mail_date);
    },

    get_mail_long_date(iso_date) {
      if (!iso_date) return "";
      let mail_date = new Date(iso_date);
      return intl_full_date.format(mail_date);
    },

    is_selected(mail_id) {
      return this.$route.params.id === mail_id;
    },

    update_unread_message_count() {
      api.messages.inbox.unread().then((response) => {
        this.$store.state.auth.user.messages = response.data.count;
      });
    },
  },
};

export default mail_mixin;
