import axios from "axios";
import router from "@/router";
// import { forceLogout, getEntity } from "./utils.js";

/**
 * Axios request interceptors
 * https://github.com/axios/axios#interceptors
 */
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

http.interceptors.request.use(
  async (request_config) => {
    request_config.headers = request_config.headers || {};
    return request_config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      // forceLogout();
    } else if (500 === error.response.status) {
      router.push({ name: "server-error" });
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
