<template>
  <article class="mail-reader">
    <div class="envelope">
      <form @submit.prevent="submit">
        <!-- SHOPPER SELECT -->
        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">To:</label>
              <div class="control has-icons-right">
                <auto-complete
                  ref="recipient_field"
                  :selection.sync="form.fields.recipient"
                  placeholder="Search for a shopper..."
                  @keyup="shopper_search"
                ></auto-complete>
                <span class="icon is-small is-right">
                  <i class="fas fa-search"></i>
                </span>
              </div>
              <field-error :message="form.field_errors.recipient" />
            </div>
          </div>
        </div>

        <!-- SUBJECT -->
        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label class="label">Subject Line:</label>
              <div class="control">
                <input
                  v-model="form.fields.subject"
                  placeholder="Ex: We have a job for you!"
                  class="input"
                  type="text"
                  name="outbox[subject]"
                />
              </div>
              <field-error :message="form.field_errors.subject" />
            </div>
          </div>
        </div>

        <!-- MESSAGE -->
        <div class="field">
          <label class="label">Message:</label>
          <div class="control">
            <textarea v-model="form.fields.body" class="input" name="outbox[body]" />
          </div>
          <field-error :message="form.field_errors.body" />
        </div>

        <div class="is-flex is-justify-content-flex-end">
          <button type="submit" class="button is-purple" :class="{ 'is-loading': is_loading }">Send</button>
        </div>
      </form>
    </div>
  </article>
</template>

<script>
import api from "@/api";
import AutoComplete from "@/components/AutoComplete";
import utils from "@/utilities";
import event_hub from "@/utilities/event_hub";

function create_fields() {
  return {
    id: null,
    recipient: "",
    subject: "",
    body: "",
    starred: false,
  };
}

export default {
  name: "NewMessage",

  components: { AutoComplete },

  data() {
    return {
      is_loading: false,
      form: {
        fields: create_fields(),
        field_errors: {},
        errors: [],
      },
    };
  },

  mounted() {
    this.initialize_shopper();
  },

  methods: {
    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.messages.outbox
        .create(this.form.fields)
        .then((response) => {
          this.message = response.data;

          event_hub.trigger("NewMessage.ADDED", response.data);

          this.$router.push({
            name: "outbox-send-success",
            query: { recipient_name: this.message.recipient_name, message_id: this.message.id },
          });
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    initialize_shopper() {
      const shopper_id = this.$route.params.shopper_id;
      if (shopper_id) {
        api.shoppers.fetch(shopper_id).then((response) => {
          this.$refs.recipient_field.select_item(response.data);
        });
      }
    },

    shopper_search(value) {
      this.is_loading = true;

      api.shoppers
        .filter({ name: value })
        .then((response) => {
          this.$refs.recipient_field.set_suggestions(response.data.results || []);
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
