import http from "@/api/http.js";

const url = "account";

function whoami() {
  return http.get(`${url}/whoami/`);
}

function login(profile) {
  return http.post(`${url}/login/`, profile);
}

function logout() {
  return http.get(`${url}/logout/`);
}

function request_password_reset(payload) {
  return http.post(`${url}/reset-password-request/`, payload);
}

function confirm_password_reset(payload) {
  return http.post(`${url}/reset-password-confirm/`, payload);
}

function fetch_settings(id) {
  return http.get(`${url}/${id}/settings/`);
}

function update_settings(id, payload) {
  return http.patch(`${url}/${id}/settings/`, payload);
}

export default {
  whoami,
  login,
  logout,
  request_password_reset,
  confirm_password_reset,
  settings: {
    fetch: fetch_settings,
    update: update_settings,
  },
};
