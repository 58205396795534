<template>
  <div class="mail-root container">
    <div v-if="auth_user.is_company" class="columns mt-6 action">
      <div class="column is-6">
        <div class="field has-addons">
          <p class="control">
            <router-link class="button" :to="{ name: 'company-inbox' }" active-class="is-active">Inbox</router-link>
          </p>
          <p class="control">
            <router-link class="button" :to="{ name: 'outbox' }" active-class="is-active">Outbox</router-link>
          </p>
        </div>
      </div>
      <div class="column is-flex is-justify-content-flex-end">
        <router-link :to="{ name: 'new-message' }" class="button is-purple">Send a message</router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Mail",

  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {},
};
</script>

<style>
.mail-root {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.mail-root .mail.columns,
.mail-root .action.columns {
  width: 1200px;
}

/* MAIL-LIST */
.mail-root .mail-list .mail-item {
  margin-bottom: 5px;
  cursor: pointer;
}

.mail-root .mail-item:not(:last-child):after {
  display: block;
  width: 100%;
  content: "";
  border-bottom: 1px solid #f2f2f2;
}

.mail-root .mail-item:hover:not(:last-child) {
  background-color: var(--light-purple);
}

.mail-root .mail-item:hover:not(:last-child):after {
  border-bottom: 1px solid #dbdbdb;
}

.mail-root .mail-list .time {
  width: 90px;
  text-align: right;
  margin-right: 2px;
}

.mail-root .mail-list .button.starred {
  width: 30px;
  text-decoration: none !important;
}

.mail-root .mail-list .button.starred:hover {
  background-color: #fafafa;
}

.mail-root .mail-list .button.starred:not(:hover) {
  color: var(--light-grey);
}

.mail-root .mail-item.selected .mail-content {
  border-left: 2px solid var(--purple);
  background-color: var(--light-purple);
}

.mail-root .mail-item .mail-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

/* DIVIDER */

.mail-root .column.is-1.divider::before {
  display: block;
  height: 100%;
  content: "";
  border-left: 1px solid #b5b5b5;
  position: relative;
  left: 50%;
}

/* MAIL-READER */

.mail-root .mail-reader {
  flex-grow: 1;
}

.mail-root .mail-reader .sender-name {
  font-size: 16px;
  font-weight: bold;
}

.mail-root .mail-reader textarea {
  height: 15rem;
}

.mail-root .mail-reader .envelope {
  background-color: var(--light-purple);
  padding: 32px 42px;
  height: 100%;
}

.mail-root .pagination {
  padding: 0 15px 0 20px;
}

/* HELPERS */
.mail-root .small-text {
  font-size: 14px;
}

.mail-root .button .icon {
  width: auto;
  height: auto;
}
</style>
