<template>
  <div class="main-layout is-flex is-flex-direction-column fill-height">
    <!-- APP HEADER -->
    <header class="app-header">
      <div class="container is-flex is-justify-content-space-between is-align-items-center fill-height">
        <div class="logo">
          <router-link :to="{ name: 'company-home' }" class="font-fredoka purple-text is-size-2">
            Margie’s List
          </router-link>
          <div class="font-poppins bold-font is-size-7">BY MYSTERYSHOPPING.COM</div>
        </div>

        <div class="spacer"></div>

        <button @click="$router.push({ name: 'company-tips' })" class="button is-ghost">
          <figure class="image is-24x24 mr-2"><img src="@/assets/images/coin.svg" /></figure>
          Tip Jar
        </button>

        <!-- DROPDOWN MENU -->
        <div ref="user_menu" class="dropdown is-right company-menu" :class="{ 'is-active': display_user_menu }">
          <div class="dropdown-trigger">
            <a class="button is-text text-decoration-none" @click="toggle_user_menu">
              <i class="far fa-user mr-2 light-grey-text" style="font-size: 28px"></i>
              <span class="light-grey-text">{{ auth_user.name }}</span>
              <span class="icon is-small">
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </a>
          </div>

          <div @click="close_user_menu" class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <router-link class="dropdown-item" :to="{ name: 'company-home' }">
                <div class="icon-text py-1">
                  <span class="icon mr-3">
                    <i class="fas fa-home is-size-5"></i>
                  </span>
                  <span>Home</span>
                </div>
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'company-profile' }">
                <div class="icon-text py-1">
                  <span class="icon mr-3">
                    <img src="@/assets/images/menu-icons/settings_icon.svg" />
                  </span>
                  <span>Settings</span>
                </div>
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'company-inbox' }">
                <div class="icon-text py-1">
                  <span class="icon mr-3">
                    <img src="@/assets/images/menu-icons/inbox_icon.svg" />
                  </span>
                  <span>Inbox</span>
                  <div class="spacer" />
                  <span class="tag is-danger is-rounded is-small">{{ auth_user.messages || 0 }}</span>
                </div>
              </router-link>
              <a class="dropdown-item" @click.prevent="$refs.help.open()">
                <div class="icon-text py-1">
                  <span class="icon mr-3">
                    <img src="@/assets/images/menu-icons/help_icon.svg" />
                  </span>
                  <span>Help</span>
                </div>
              </a>
              <hr class="dropdown-divider" />
              <a class="dropdown-item" @click.prevent="logout">
                <div class="icon-text py-1">
                  <span class="icon mr-3">
                    <img src="@/assets/images/menu-icons/logout_icon.svg" />
                  </span>
                  <span>Logout</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section>
      <keep-alive :include="keep_alive_routes">
        <router-view />
      </keep-alive>
    </section>

    <footer>
      <div class="copyright container">© Copyright MysteryShopping.com, 2021</div>
    </footer>

    <help ref="help" />
  </div>
</template>

<script>
import api from "@/api";
import event_hub from "@/utilities/event_hub.js";
import Help from "../components/Help";
import mail_mixin from "@/mixins/mail";

document.addEventListener("click", ($event) => {
  event_hub.trigger("UserMenu.close", $event);
});

export default {
  name: "MainCompanyLayout",

  components: { Help },

  mixins: [mail_mixin],

  data() {
    return {
      display_user_menu: false,
      keep_alive_routes: ["ShopperSearch", "ShopperTipJar"],
    };
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    this.update_unread_message_count();
    event_hub.listen("UserMenu.close", ($event) => {
      if (!$event.path.includes(this.$refs.user_menu)) {
        this.close_user_menu();
      }
    });
  },

  methods: {
    close_user_menu() {
      this.display_user_menu = false;
    },

    toggle_user_menu() {
      this.display_user_menu = !this.display_user_menu;
    },

    logout() {
      api.accounts.logout().then(() => {
        this.$store.state.auth.user = { anonymous: true };
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style>
.main-layout {
  background-color: #fff;
  /* background-color: red; */
  flex: 1;
}

.main-layout > header {
  height: 138px;
  background-color: var(--light-purple);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.main-layout > section {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.main-layout > footer {
  height: 100px;
  background-color: var(--light-purple);
}

.main-layout > header .login.button {
  font-family: var(--font-family-fredoka);
  font-size: var(--font-size-m);
  color: var(--purple);
}

.main-layout > footer .copyright {
  min-height: 100%;
  color: var(--light-grey);

  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.company-menu .dropdown-menu i {
  color: var(--purple);
}
</style>
