function delete_item(list, item) {
  list.splice(
    list.findIndex((x) => x.id === item.id),
    1
  );
}
function add_item(list, item) {
  list.unshift(item);
}
function update_item(list, item) {
  return list.map((obj) => (item.id === obj.id ? item : obj));
}

export default {
  delete_item,
  add_item,
  update_item,
};
