// OBJECT TYPE VERIFICATION
let core_types = {
  "[object Boolean]": "boolean",
  "[object Number]": "number",
  "[object String]": "string",
  "[object Function]": "function",
  "[object Array]": "array",
  "[object Date]": "date",
  "[object RegExp]": "regexp",
  "[object Object]": "object",
  "[object Error]": "error"
};

let to_string = core_types.toString;

export default function type(object) {
  let typeof_object = typeof object;

  if (object === null) {
    return "null";
  }

  if (typeof_object === "object" || typeof_object === "function") {
    return core_types[to_string.call(object)] || "object";
  }

  return typeof_object;
}

export function is_window(object) {
  return object !== null && object === object.window;
}

export function is_numeric(object) {
  return !isNaN(parseFloat(object)) && isFinite(object);
}

export function is_undefined(object) {
  return typeof object === "undefined";
}

export function is_array(object) {
  return type(object) === "array";
}

export function is_function(object) {
  return type(object) === "function";
}

export function is_string(object) {
  return type(object) === "string";
}

export function is_object(object) {
  return type(object) === "object";
}

export function is_boolean(object) {
  return type(object) === "boolean";
}

export function is_regexp(object) {
  return type(object) === "regexp";
}

export function is_element(object) {
  let html_element = typeof HTMLElement === "object";
  let is_html_element = object instanceof HTMLElement;
  let is_object = typeof object === "object";
  let is_node_one = object.nodeType === 1;
  let has_node_name = typeof object.nodeName === "string";
  let is_not_html_element = object && is_object && is_node_one && has_node_name;

  return html_element ? is_html_element : is_not_html_element;
}
