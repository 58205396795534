<template>
  <div>
    <div class="columns">
      <h3 class="font-poppins">Billing Information</h3>
      <div class="spacer"></div>
      <span class="edit-icon">
        <i
          class="fa fa-pen is-clickable"
          @click.prevent="
            $router.push({
              name: 'company-subscribe',
              params: { id: auth_user.company_details.subscription_id },
            })
          "
        ></i>
      </span>
    </div>

    <div class="field mt-6">
      <label class="label">Cardholder Name</label>
      <div class="control">
        <input v-model="subscription.cardholder_name" class="input" type="text" disabled />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field mt-6">
          <label class="label">Card Ending</label>
          <div class="control">
            <input v-model="subscription.card_number" class="input" type="text" disabled />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field mt-6">
          <label class="label">Exp Month</label>
          <div class="control">
            <input v-model="subscription.exp_month" class="input" type="text" disabled />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field mt-6">
          <label class="label">Exp Year</label>
          <div class="control">
            <input v-model="subscription.exp_year" class="input" type="text" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "BillingDetails",

  data() {
    return {
      subscription: {},
    };
  },

  computed: {
    auth_user() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      api.subscriptions
        .fetch(this.auth_user.company_details.subscription_id)
        .then((response) => {
          this.subscription = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
