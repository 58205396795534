<template>
  <public-layout>
    <div class="landing-page container">
      <h1 class="font-poppins">Cut the mystery out of mystery shoppers.</h1>

      <div class="actions">
        <router-link :to="{ name: 'shopper-sign-up' }" class="button is-purple is-medium mr-3">
          Shopper Sign Up
        </router-link>
        <router-link :to="{ name: 'company-sign-up' }" class="button is-purple is-medium">Company Sign Up</router-link>
      </div>
    </div>
  </public-layout>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style>
.landing-page {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landing-page h1 {
  max-width: 724px;

  display: block;
  margin: 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 45px;
  color: var(--dark-grey);
}

.landing-page .actions {
  margin-top: 72px;
}

.landing-page .actions button {
  width: 300px;
  border-radius: 10px;

  font-family: var(--font-family-poppins);
  font-weight: bold;
}
</style>
