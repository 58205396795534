import { is_object } from "./object_type";
import is_empty from "./is_empty";

let generic_error = {
  message: "There was an issue performing this action, if this persist please contact us.",
  type: "error",
};

/**
 * Extracts field_errors details from an API Response Exception
 * @param {Object} exception
 * @return Dictionary with any key-value pairs of field errors.
 */
export function get_field_errors(exception) {
  let data = exception && exception.response ? exception.response.data : {};
  let field_errors = is_object(data) && data.field_errors ? data.field_errors : [];
  let mapped_errors = {};

  for (let i = 0; i < field_errors.length; i++) {
    let error = field_errors[i];
    mapped_errors[error.field] = error.message;
  }

  return mapped_errors;
}

/**
 * Extracts non field_errors details from an API Response Exception
 * @param {Object} exception
 * @return Array with a list of generic error objects.
 */
export function get_errors(exception) {
  const data = exception && exception.response ? exception.response.data : {};
  return data.errors || [];
}

export function set_errors(form, exception, generic_error = generic_error) {
  let field_errors = get_field_errors(exception);
  let errors = get_errors(exception);

  if (generic_error && is_empty(field_errors) && is_empty(errors)) {
    errors = [generic_error];
  }

  form.field_errors = field_errors;
  form.errors = errors;
}

export default {
  get_field_errors,
  get_errors,
  set_errors,
  generic_error,
};
