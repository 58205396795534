<template>
  <public-layout>
    <div v-if="!display_confirm_registration" class="shopper-sign-up container">
      <h1 class="font-poppins">Become a Mystery Shopper</h1>

      <form @submit.prevent="submit">
        <div class="field mt-6">
          <label class="label">Name</label>
          <div class="control">
            <input v-model="form.fields.name" class="input" type="text" name="Shopper[Name]" />
          </div>
          <field-error :message="form.field_errors.name" />
        </div>

        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input v-model="form.fields.email" class="input" type="email" name="Shopper[Email]" />
          </div>
          <field-error :message="form.field_errors.email" />
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input v-model="form.fields.password" class="input" type="password" name="Shopper[Password]" />
          </div>
          <field-error :message="form.field_errors.password" />
        </div>

        <div class="field columns">
          <div class="column py-0">
            <v-date-picker
              mode="date"
              ref="datepicker"
              v-model="form.fields.date_of_birth"
              :masks="date_picker_config.masks"
              :model-config="date_picker_config.model"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <label class="label">Date of birth</label>
                <div class="field has-addons mb-0">
                  <div class="control is-expanded">
                    <input
                      name="Shopper[DateOfBirth]"
                      class="input is-clickable"
                      type="text"
                      v-model="form.fields.date_of_birth"
                      placeholder="YYYY-MM-DD"
                      @click="togglePopover"
                    />
                  </div>
                  <div class="control">
                    <a class="button is-info" @click="togglePopover"><i class="fas fa-calendar-day"></i></a>
                  </div>
                </div>
                <field-error :message="form.field_errors.date_of_birth" />
              </template>
            </v-date-picker>
          </div>

          <div class="column py-0">
            <div class="field">
              <label class="label">Race</label>
              <div class="control is-expanded">
                <RaceSelector :selection.sync="form.fields.race" class="is-fullwidth"></RaceSelector>
              </div>
              <field-error :message="form.field_errors.race" />
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Address</label>
          <div class="control">
            <input v-model="form.fields.address" class="input" type="text" name="Shopper[Address]" />
          </div>
          <field-error :message="form.field_errors.address" />
        </div>

        <div class="field">
          <label class="label">Zipcode</label>
          <div class="control">
            <input
              v-model="form.fields.zip_code"
              class="input"
              type="text"
              name="Shopper[Zipcode]"
              @keyup="verify_zip_code"
            />
          </div>
          <field-error :message="form.field_errors.zip_code" />
        </div>

        <div class="field">
          <label class="label">City</label>
          <div class="control">
            <input v-model="form.fields.city" class="input" type="text" name="Shopper[City]" disabled />
          </div>
          <field-error :message="form.field_errors.city" />
        </div>

        <div class="field">
          <label class="label">State</label>
          <div class="control">
            <USStateSelector :selection.sync="form.fields.state" class="is-fullwidth" disabled></USStateSelector>
          </div>
          <field-error :message="form.field_errors.state" />
        </div>

        <div class="field">
          <label class="label">Phone</label>
          <div class="control">
            <input v-model="form.fields.phone" class="input" type="text" name="Shopper[Phone]" />
          </div>
          <field-error :message="form.field_errors.phone" />
        </div>

        <div class="field is-grouped my-6">
          <div class="control is-expanded">
            <label class="label">Do you have experience mystery shopping?</label>
          </div>

          <div class="control">
            <label class="radio">
              <input
                name="mystery_shopping_experience"
                type="radio"
                v-model="form.fields.has_mystery_shopping_experience"
                :value="true"
              />
              Yes
            </label>
            <label class="radio">
              <input
                name="mystery_shopping_experience"
                type="radio"
                v-model="form.fields.has_mystery_shopping_experience"
                :value="false"
              />
              No
            </label>
          </div>

          <field-error :message="form.field_errors.has_mystery_shopping_experience" />
        </div>

        <div class="field">
          <label class="label">List any companies you have worked for and any certifications you have.</label>
          <div class="control">
            <textarea
              name="Shopper[CompaniesAndCertificates]"
              class="textarea has-fixed-size"
              rows="4"
              v-model="form.fields.previous_companies_and_certificates"
            ></textarea>
          </div>
        </div>

        <div class="field is-grouped my-6">
          <div class="control is-expanded">
            <label class="label">Do you have experience video shopping?</label>
          </div>

          <div class="control">
            <label class="radio">
              <input
                v-model="form.fields.has_video_shopping_experience"
                type="radio"
                name="video_shopping_experience"
                :value="true"
              />
              Yes
            </label>

            <label class="radio">
              <input
                v-model="form.fields.has_video_shopping_experience"
                type="radio"
                name="video_shopping_experience"
                :value="false"
              />
              No
            </label>
          </div>

          <field-error :message="form.field_errors.has_video_shopping_experience" />
        </div>

        <div class="field">
          <label class="label">What type of equipment or app do you use?</label>
          <div class="control">
            <textarea
              v-model="form.fields.types_of_equipment_or_apps_used"
              class="textarea has-fixed-size"
              rows="4"
              name="Shopper[EquipmentUsed]"
            ></textarea>
          </div>

          <field-error :message="form.field_errors.types_of_equipment_or_apps_used" />
        </div>

        <div class="field">
          <label class="label">Please list any equipment that you own.</label>
          <div class="control">
            <textarea
              v-model="form.fields.equipment_owned"
              class="textarea has-fixed-size"
              rows="4"
              name="Shopper[EquipmentOwned]"
            ></textarea>
          </div>
          <field-error :message="form.field_errors.equipment_owned" />
        </div>

        <div class="field">
          <div class="control">
            <button type="submit" class="button submit is-purple is-medium" :class="{ 'is-loading': is_loading }">
              Register
            </button>
          </div>
        </div>
      </form>

      <div class="my-6">
        <div class="have-an-account dark-grey-text">
          <span>Already have an account?</span>
          <router-link :to="{ name: 'login' }" class="ml-2 bold-font dark-grey-text anchor-border">Log in</router-link>
        </div>
      </div>
    </div>

    <ConfirmRegistration v-if="display_confirm_registration">
      <router-link :to="{ name: 'login' }" class="button is-purple">Log in</router-link>
    </ConfirmRegistration>
  </public-layout>
</template>

<script>
import RaceSelector from "@/components/RaceSelector.vue";
import ConfirmRegistration from "@/components/ConfirmRegistration.vue";

import api from "@/api";
import utils from "@/utilities";
import debounce from "lodash/debounce";

const generic_error = {
  message: "There was an issue performing this action, if this persist please contact us.",
  type: "error",
};

export default {
  name: "ShopperSignUp",

  components: {
    RaceSelector,
    ConfirmRegistration,
  },

  props: {},

  data() {
    return {
      is_loading: false,
      display_confirm_registration: false,

      form: {
        fields: {
          name: "",
          email: "",
          password: "",

          address: "",
          state: "",
          zip_code: "",
          city: "",
          phone: "",

          date_of_birth: "",
          race: "",

          has_mystery_shopping_experience: null,
          has_video_shopping_experience: null,
          previous_companies_and_certificates: "",
          types_of_equipment_or_apps_used: "",
          equipment_owned: "",
        },

        field_errors: {},
        errors: [],
      },

      date_picker_config: {
        masks: {
          input: "YYYY-MM-DD",
          iso: "YYYY-MM-DD",
        },
        model: {
          type: "string",
          mask: "YYYY-MM-DD",
        },
      },
    };
  },

  computed: {},

  mounted() {},

  methods: {
    reset_validation() {
      this.form.field_errors = {};
      this.form.errors = [];
    },

    submit() {
      this.reset_validation();
      this.is_loading = true;

      api.shoppers
        .create(this.form.fields)
        .then(() => {
          this.display_confirm_registration = true;
        })
        .catch((exception) => {
          utils.form.set_errors(this.form, exception, generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    verify_zip_code: debounce(function () {
      this.is_loading = true;
      const address = this.form.fields;
      api.zip_codes
        .fetch_public(address.zip_code)
        .then((response) => {
          address.state = response.data.state;
          address.city = response.data.city;
          this.form.field_errors.zip_code = null;
          this.form.errors = [];
        })
        .catch((e) => {
          utils.form.set_errors(this.form, e, utils.form.generic_error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    }, 500),
  },
};
</script>

<style>
.shopper-sign-up {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-sign-up h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: var(--dark-grey);
}

.shopper-sign-up form {
  width: 724px;
}

.shopper-sign-up .field .label {
  color: var(--dark-grey);
}

.shopper-sign-up .field + .field {
  margin-top: 30px;
}

.shopper-sign-up form button.submit {
  width: 100%;
}
</style>
