<template>
  <div class="notification is-purple p-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: "InfoNotification",
};
</script>

<style scoped>
.notification.is-purple {
  background-color: var(--light-purple);
  color: var(--purple);
}
</style>
