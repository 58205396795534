<template>
  <div class="shopper-search-list container">
    <h1 class="font-poppins">Search for Shoppers</h1>
    <div class="block">
      <form @submit.prevent="new_search">
        <div class="columns is-centered">
          <div class="column">
            <div class="field">
              <div class="control">
                <USStateSelector :selection.sync="filters.state" class="is-fullwidth"></USStateSelector>
              </div>
            </div>
          </div>
          <div class="column">
            <v-date-picker mode="date" ref="datepicker" v-model="filters.availability_date">
              <template v-slot="{ inputValue, togglePopover }">
                <div class="field">
                  <div class="control has-icons-right mb-0">
                    <input
                      name="filters[Date]"
                      class="input is-clickable"
                      type="text"
                      :value="inputValue"
                      @click="togglePopover"
                      readonly
                      placeholder="Date"
                    />
                    <span class="icon is-small is-right">
                      <i class="fas fa-calendar-day"></i>
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <div class="control">
                  <input
                    v-model="filters.zip_code"
                    placeholder="Zip Code"
                    class="input"
                    type="text"
                    name="filters[Zip Code]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <button type="submit" :class="{ 'is-loading': is_loading }" class="button submit is-purple is-fullwidth">
              Search
            </button>
          </div>
        </div>
        <div class="field is-grouped">
          <label class="label mt-3 mr-3">Has video shopping experience?</label>
          <div class="select">
            <select name="filters[Has Video Shopping Experience]" v-model="filters.has_video_shopping_experience" :disabled="is_loading">
              <option selected value="">All</option>
              <option selected :value="true">Yes</option>
              <option selected :value="false">No</option>
            </select>
          </div>
        </div>
      </form>
    </div>

    <!-- CLEAR SEARCH -->
    <a v-if="is_dirty" @click.prevent="clear_search">
      <i class="fa fa-ban"></i>
      Clear Search
    </a>

    <!-- SEARCH RESULTS -->
    <div v-if="availability_list.length < 1" class="block">No Results</div>
    <table v-else class="table is-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone number</th>
          <th>View Profile</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(availability, index) in availability_list" :key="index">
          <td>
            {{ availability.name }}
          </td>
          <td>{{ availability.email }}</td>
          <td>{{ availability.phone }}</td>
          <td>
            <router-link
              v-if="availability.id"
              :to="{ name: 'company-shopper-detail', params: { id: availability.id } }"
            >
              <i class="fas fa-eye"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </table>

    <!-- PAGINATION -->
    <div class="columns">
      <div class="column is-offset-5">
        <paginator @update:options="update_page" :options.sync="page_options" />
      </div>
    </div>

    <!-- SHOPPER DETAIL DIALOG-->
    <shopper-detail ref="shopper_detail">
      <template v-slot:action="shopper">
        <button
          class="button is-purple is-fluid"
          @click.prevent="$router.push({ name: 'new-shopper-message', params: { shopper_id: shopper.shopper.id } })"
        >
          Send a message
        </button>
      </template>
    </shopper-detail>
  </div>
</template>

<script>
import api from "@/api";
import util from "@/utilities/date";
import ShopperDetail from "../components/ShopperDetail";

const get_filters = function () {
  return {
    state: "",
    availability_date: "",
    zip_code: "",
    has_video_shopping_experience: "",
  };
};

export default {
  name: "ShopperSearch",

  components: { ShopperDetail },

  props: {},

  mounted() {
    const shopper_id = this.$route.params.shopper_id;
    if (shopper_id) this.$refs.shopper_detail.open({ id: shopper_id });
  },

  data() {
    return {
      is_loading: false,

      availability_list: [],

      filters: get_filters(),

      show_detail: false,

      availability_detail: {},

      page_options: {
        page: 1,
        page_size: 10,
        total_items: 0,
      },
    };
  },

  computed: {
    is_dirty() {
      return this.filters.state || this.filters.availability_date || this.availability_list.length > 0;
    },
  },

  methods: {
    new_search() {
      this.switch_to_first_page();
      this.search();
    },
    search() {
      this.is_loading = true;
      this.filters.page = this.page_options.page;
      this.filters.page_size = this.page_options.page_size;

      const filters = Object.assign({}, this.filters);
      if (filters.availability_date) filters.availability_date = util.to_django_date_field(filters.availability_date);

      api.shoppers
        .filter(filters)
        .then((response) => {
          this.availability_list = response.data.results || [];
          this.page_options.total_items = response.data.count;
        })
        .catch(() => {})
        .finally(() => {
          this.is_loading = false;
        });
    },

    open_detail(details) {
      this.$refs.shopper_detail.open(details);
    },
    update_page() {
      this.search();
    },

    clear_search() {
      this.filters = get_filters();
      this.availability_list = [];
      this.switch_to_first_page();
    },

    switch_to_first_page() {
      this.page_options.page = 1;
      this.page_options.total_items = 0;
    },
  },
};
</script>

<style>
.shopper-search-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  align-items: center;
  flex-direction: column;
}

.shopper-search-list h1 {
  width: 724px;

  display: block;
  margin: 80px auto 0 auto;

  text-align: center;
  font-weight: 700;
  font-size: 26px;
  color: var(--dark-grey);
}

.shopper-search-list .columns {
  width: 824px;
  margin-top: 30px;
}

.shopper-search-list .columns a {
  display: block;
  text-align: center;
  line-height: 2.5;
}

/* .shopper-search-list .columns .column:nth-child(3n + 2) a {
  background-color: var(--light-purple);
} */

.shopper-search-list .columns a {
  background-color: var(--light-purple);
}

.shopper-search-list .columns a:hover {
  background-color: var(--purple);
  color: #fff;
}

.shopper-search-list table {
  width: 824px;
  margin-top: 30px;
  margin-bottom: 80px;
}

.shopper-search-list table thead th,
.shopper-search-list table tbody td,
.shopper-search-list table tfoot td {
  border: none;
}

.shopper-search-list table thead th {
  color: var(--dark-grey);
  font-weight: 500;
}

.shopper-search-list table tbody td {
  line-height: 2.25;
  color: var(--light-grey);
}

.shopper-search-list table tbody td .start-date {
  /* background-color: silver; */
  width: 50px;
  display: inline-block;
}

.shopper-search-list table tfoot td {
  padding-top: 30px;
  text-align: center;
}

.shopper-search-list .coin {
  width: 16px;
  height: 16px;
}

.shopper-search-list .media + .media {
  border-top: none;
}
</style>
