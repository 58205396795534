<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content">
      <div class="card shopper-detail-modal">
        <span class="is-clickable mx-2 my-1 is-pulled-right" aria-label="close" @click.prevent="close">&#10006;</span>
        <div class="card-content px-6 py-5">
          <div class="media">
            <div class="media-left">
              <div class="initials is-size-3 has-text-weight-light">
                {{ shopper_initials }}
              </div>
            </div>
            <div class="media-content media-centered"></div>
          </div>

          <div class="content">
            <div class="block">
              <p class="font-poppins is-size-5 has-text-weight-medium">{{ shopper.name }}</p>
            </div>

            <div class="media border-b-0">
              <div class="media-left">
                <i class="far fa-square"></i>
              </div>
              <div class="media-content">
                <div class="content">
                  <span class="has-text-weight-medium">Background Check</span>
                </div>
              </div>
            </div>

            <div class="media border-t-0 mt-0 pt-0">
              <div class="media-left">
                <img class="coin" src="@/assets/images/coin.svg" />
              </div>
              <div class="media-content">
                <div class="content">
                  <span class="has-text-weight-medium">Coins: {{ shopper.coins }}</span>
                </div>
              </div>
            </div>
            <div class="block">
              <div class="is-size-6 has-text-weight-medium">Equipment:</div>
              <p class="has-text-weight-light">
                {{ shopper.types_of_equipment_or_apps_used }}
                {{ shopper.equipment_owned }}
              </p>
              <p>
                <router-link v-if="shopper.id" :to="{ name: 'company-shopper-detail', params: { id: shopper.id } }">
                  More details...
                </router-link>
              </p>
              <slot name="action" v-bind:shopper="shopper" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import initials from "@/utilities/initials";

export default {
  name: "ShopperDetail",

  data() {
    return {
      shopper: {},

      show: false,

      is_loading: false,
    };
  },

  computed: {
    shopper_initials() {
      return initials.get_initials(this.shopper.name);
    },
  },

  methods: {
    open(shopper) {
      this.shopper = shopper;
      this.is_loading = true;
      this.show = true;

      api.shoppers
        .fetch(shopper.id)
        .then((response) => {
          this.shopper = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    close() {
      this.show = false;
      this.shopper = {};
    },
  },
};
</script>

<style>
.modal .shopper-detail-modal {
  max-width: 400px;
}

.shopper-detail-modal .initials {
  height: 100px;
  width: 100px;
  background-color: var(--light-purple);
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: var(--purple);
}
</style>
