import { format, parseISO } from "date-fns";

function is_today(date_object) {
  const today = new Date();

  return (
    date_object.getDate() === today.getDate() &&
    date_object.getMonth() === today.getMonth() &&
    date_object.getFullYear() === today.getFullYear()
  );
}

function from_django_date_field(date) {
  return date ? parseISO(date) : new Date();
}

function to_django_date_field(date) {
  return format(date, "yyyy-MM-dd");
}

function to_django_time_field(date) {
  return date.toISOString();
}

export default {
  is_today,
  from_django_date_field,
  to_django_date_field,
  to_django_time_field,
};
