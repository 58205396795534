import form from "./form.js";
import type from "./object_type.js";
import date from "./date.js";

import is_equal from "./is_equal";
import is_empty from "./is_empty";
import initials from "./initials";
import list from "./list";
import account from "./account";
import event_hub from "./event_hub";

export function resolve_namespace(context, field) {
  const parts = field.split(".");
  let reference = context;

  for (let i = 0; i < parts.length; i++) {
    reference = reference[parts[i]];
  }

  return reference;
}

export default {
  resolve_namespace,
  is_equal,
  form,
  date,
  type,
  is_empty,
  initials,
  list,
  account,
  event_hub,
};
