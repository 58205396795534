<template>
  <div class="select race" :class="classes">
    <select v-model="selection" @change="change" :disabled="disabled">
      <option selected></option>
      <option v-for="(option, index) in options" :key="index" :value="option.value">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "RaceSelector",

  components: {},

  props: {
    classes: { type: Array, default: () => [] },
    selection: {
      type: String,
    },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      options: [
        { text: "European American (White)", value: "european" },
        { text: "African American (Black)", value: "african" },
        { text: "American Indian / Alaska Native", value: "american_indian" },
        { text: "Asian American", value: "asian" },
        { text: "Native Hawaiian / Other Pacific Islander", value: "pacific_islander" },
        { text: "Hispanic / Latino", value: "hispanic" },
        { text: "Other", value: "other" },
      ],
    };
  },

  computed: {},

  methods: {
    change() {
      this.$emit("update:selection", this.selection);
    },
  },
};
</script>

<style></style>
