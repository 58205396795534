<template>
  <article class="mail-reader success">
    <div class="envelope">
      <div class="card-content px-6 py-6 has-text-centered is-justify-content-center">
        <i class="fa fa-paper-plane"></i>
        <div class="font-poppins is-size-2 has-text-weight-medium">Message Sent</div>
        <div class="font-poppins is-size-5 has-text-weight-medium mt-4">
          You just sent a message to
          <br />
          <strong>{{ recipient_name }}</strong>
        </div>
        <router-link
          v-if="message_id"
          class="button is-purple mt-5"
          :to="{ name: 'outbox-message', params: { id: message_id } }"
        >
          View Message
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Success",

  computed: {
    recipient_name() {
      return this.$route.query.recipient_name || "Someone";
    },
    message_id() {
      return this.$route.query.message_id || null;
    },
  },
};
</script>

<style>
.success .fa-paper-plane {
  font-size: 100px;
  color: var(--purple);
  margin-bottom: 2rem;
}
</style>
