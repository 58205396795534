<template>
  <span class="icon" :class="{ 'has-text-success': value, 'has-text-danger': !value }">
    <i class="is-success fas" :class="{ 'fa-check': value, 'fa-times': !value }"></i>
  </span>
</template>

<script>
export default {
  name: "StaticCheckBox",

  props: { value: { type: Boolean, required: true } },
};
</script>

<style scoped></style>
