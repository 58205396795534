import http from "@/api/http.js";

const url = "shoppers";
const availability_url = "shoppers/availability";
const company_url = "companies";
const tip_url = "shoppers/tips";

function fetch(id) {
  return http.get(`${url}/${id}/`);
}

function create(profile) {
  return http.post(`${url}/`, profile);
}

function update(id, profile) {
  return http.patch(`${url}/${id}/`, profile);
}

function filter(params) {
  return http.get(`${url}/`, { params: params });
}

function availability_fetch(id) {
  return http.get(`${availability_url}/${id}/`);
}

function availability_fetch_all(options) {
  let query = {
    page: 1,
    page_size: 5,
    ...options,
  };

  return http.get(`${availability_url}/`, { params: query });
}

function availability_filter(params) {
  return http.get(`${availability_url}/`, { params: params });
}

function availability_create(payload) {
  return http.post(`${availability_url}/`, payload);
}

function availability_update(payload) {
  return http.put(`${availability_url}/${payload.id}/`, payload);
}

function availability_delete(id) {
  return http.delete(`${availability_url}/${id}/`);
}

function company_fetch_all(options) {
  let query = {
    page: options.page || 1,
    page_size: options.page_size || 5,
  };
  return http.get(`${company_url}/`, { params: query });
}

function send_tip(payload) {
  return http.post(`${tip_url}/`, payload);
}

export default {
  fetch,
  create,
  filter,
  update,

  tip: {
    send: send_tip,
  },

  availability: {
    fetch: availability_fetch,
    fetch_all: availability_fetch_all,
    create: availability_create,
    update: availability_update,
    filter: availability_filter,
    delete: availability_delete,
  },

  company: {
    fetch_all: company_fetch_all,
  },
};
